import React from "react";
// import "./PlantSelection.css";
import "./BookFlip.css";
import selection_1 from "../../Assets/selection_1.jpg";
import selection_2 from "../../Assets/selection_2.jpg";
import selection_3 from "../../Assets/selection_3.jpg";
import selection_4 from "../../Assets/selection_4.jpg";
import selection_5 from "../../Assets/selection_5.jpg";
import selection_6 from "../../Assets/selection_6.JPG";
import selection_7 from "../../Assets/selection_7.jpg";
import selection_8 from "../../Assets/selection_8.jpg";
import selection_9 from "../../Assets/selection_9.jpg";
import selection_10 from "../../Assets/selection_10.jpg";
import selection_11 from "../../Assets/selection_11.jpg";
import logo from "../../Assets/logo.png";

export const PlantSelection = () => {
  return (
    <div className="book">
      <div className="plant-imges">
        <div className="row">
          <div
            className="column img-text"
            style={{ width: "100%", height: "165px" }}
          >
            <div>
              <h3
                style={{ fontSize: "16px", fontWeight: "100", margin: "10px" }}
              >
                SANDALWOOD PLANT SELECTION
              </h3>
            </div>
            <div>
              <p style={{ fontSize: "9px", margin: "10px" }}>
                Selecting high-quality sandalwood plants is pivotal for
                establishing a successful and productive sandalwood cultivation
                venture. Diligence in sourcing and caring for the sandalwood
                plants will contribute to the long-term success of your
                sandalwood plantation.
              </p>
            </div>
          </div>
          <div className="column">
            <div>
              <img
                src={selection_1}
                alt="plant-pot"
                style={{ width: "100%", height: "165px" }}
              />
            </div>
          </div>
          <div className="column">
            <div>
              <img
                src={selection_2}
                alt="tree-row"
                style={{ width: "100%", height: "165px" }}
              />
            </div>
          </div>
          <div
            className="column img-text"
            style={{ width: "100%", height: "165px" }}
          >
            <div>
              <h3
                style={{ fontSize: "16px", fontWeight: "100", margin: "10px" }}
              >
                FINAL TRAINING PROGRAMME
              </h3>
            </div>
            <div>
              <p style={{ fontSize: "9px", margin: "10px" }}>
                Post plantation phase it is very important that the care taker
                of the plantation must be trained on aspects such as watering,
                maintenance, pest management etc. He must be provided with a
                Do's and Don'ts checklist.
              </p>
            </div>
          </div>
          <div className="column">
            <div>
              <img
                src={selection_3}
                alt="plant-seed"
                style={{ width: "100%", height: "165px" }}
              />
            </div>
          </div>
          <div className="column">
            <div>
              <img
                src={selection_4}
                alt="tablate-plant-img"
                style={{ width: "100%", height: "165px" }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="column">
            <div>
              <img
                src={selection_11}
                alt="brown-pot"
                style={{ width: "100%", height: "165px" }}
              />
            </div>
          </div>
          <div className="column">
            <div>
              <img
                src={selection_5}
                alt="man-discussion"
                style={{ width: "100%", height: "165px" }}
              />
            </div>
          </div>
          <div className="column img-text">
            <div>
              <h3
                style={{ fontSize: "16px", fontWeight: "100", margin: "10px" }}
              >
                SANDALWOOD HOST PLANT SELECTION
              </h3>
            </div>
            <div>
              <p style={{ fontSize: "9px", margin: "10px" }}>
                Sandalwood cultivation requires careful selection of suitable
                host plants to ensure ptimal growth and high yields.
              </p>
            </div>
          </div>
          <div className="column">
            <div>
              <img
                src={selection_6}
                alt="man-chair-discussion"
                style={{ width: "100%", height: "165px" }}
              />
            </div>
          </div>
          <div className="column">
            <div>
              <img
                src={selection_7}
                alt="man-discussion"
                style={{ width: "100%", height: "165px" }}
              />
            </div>
          </div>
          <div className="column">
            <div>
              <img
                src={selection_8}
                alt="man-laptop-couple"
                style={{ width: "100%", height: "165px" }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div
            className="column img-text"
            style={{ width: "100%", height: "165px" }}
          >
            <div>
              <h3
                style={{ fontSize: "16px", fontWeight: "100", margin: "10px" }}
              >
                SANDALWOOD AND HOST PLANTATION
              </h3>
            </div>
            <div>
              <p style={{ fontSize: "9px", margin: "10px" }}>
                Sandalwood plants, thoughtfully chosen, are scientifically and
                meticulously plant- ed alongside their host plants.
              </p>
            </div>
          </div>
          <div className="column">
            <div>
              <img
                src={selection_9}
                alt="plantation"
                style={{ width: "100%", height: "165px" }}
              />
            </div>
          </div>
          <div className="column">
            <div>
              <img
                src={selection_10}
                alt="plant-choose"
                style={{ width: "100%", height: "165px" }}
              />
            </div>
          </div>
          <div className="column" style={{ flex: "3" }}>
            <div
              className="selection-logo"
              style={{ width: "100%", height: "165px" }}
            >
              <img src={logo} alt="logo" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PlantSelection;
