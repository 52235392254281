import React from "react";
import "./Categories.css";
import product_1 from "../../Assets/Hargharchandan.jpg";
import product_2 from "../../Assets/unique_gifting.jpg";
import product_3 from "../../Assets/product_3.jpg";
import category_flower from "../../Assets/category-flower-img.png";

export const Categories = () => {
  return (
    <div className="catmm">
      <div className="catmain position-relative">
        <img className="category-flower" src={category_flower} alt="" />
        <div className="left-container">
          <h2>Our <br /> Categories</h2>
          <h3>Explore our wide range of categories of sandalwood</h3>
        </div>

        <div className="right-container ctg">
          <div className="card" key={1}>
            <img id="catcrd" src={product_1} alt="img1" />
            <div className="cardbody">
              <h3>Har Ghar Chandan</h3>
            </div>
          </div>

          <div className="card" key={2}>
            <img id="catcrd" src={product_2} alt="img2" />
            <div className="cardbody">
              <h3>Unique Gifting</h3>
            </div>
          </div>

          <div className="card" key={3}>
            <img id="catcrd" src={product_3} alt="img3" />
            <div className="cardbody">
              <h3>Handicrafts</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Categories;
