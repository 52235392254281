import React, { useState } from "react";
import "../../Styles/Farmland.css";
import servicespage1 from "../.././Assets/servicespage1.jpeg";
import leaf from "../.././Assets/gift-vector-leaf.png";
import gift1 from "../.././Assets/gift-faq-one.png";
import gift2 from "../.././Assets/gift-faq-two.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import Layout from "../.././Components/Layout/Layout";
import Footer from "../.././Components/Layout/Footer";
import BookFlip from "./BookFlip";

const Farmland = () => {
  const [cartItems, setCartItems] = useState([]);
  const [openFaqIndex, setOpenFaqIndex] = useState(null);
  const [showBookFlip, setShowBookFlip] = useState(false); 

  const toggleFaq = (index) => {
    setOpenFaqIndex(openFaqIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "1. Can you tell us the forte of your company?",
      answer:
        "The strength of our company lies upon its core values in terms of ethics, integrity, trust and loyalty towards our customer.",
    },
    {
      question: "2. Where is your company located?",
      answer:
        "The strength of our company lies upon its core values in terms of ethics, integrity, trust and loyalty towards our customer.",
    },
    {
      question:
        "3. Which all part of the country does your company provide its services?",
      answer:
        "The strength of our company lies upon its core values in terms of ethics, integrity, trust and loyalty towards our customer.",
    },
    {
      question: "4. Which sandalwood does your company deal with?",
      answer:
        "The strength of our company lies upon its core values in terms of ethics, integrity, trust and loyalty towards our customer.",
    },
    {
      question: "5. What are the different names of sandalwood?",
      answer:
        "The strength of our company lies upon its core values in terms of ethics, integrity, trust and loyalty towards our customer.",
    },
    {
      question: "6. Is red sandalwood and yellow sandalwood same?",
      answer:
        "The strength of our company lies upon its core values in terms of ethics, integrity, trust and loyalty towards our customer.",
    },
    {
      question:
        "7. What are the main differences between red and yellow sandalwood?",
      answer:
        "The strength of our company lies upon its core values in terms of ethics, integrity, trust and loyalty towards our customer.",
    },
  ];

  return (
    <Layout
      setCartItems={setCartItems}
      cartItems={cartItems}
      isNavbar={true}
      textColor="white"
    >
      <div className="farmland-container">
        <div className="farmland-text">
          <span className="text1">Farmland</span>{" "}
          <span className="text2">sandalwood plantation</span>
        </div>
      </div>
      <div className="farmland-info-section">
        <div className="farmland-info-content-wrapper">
          <img src={leaf} alt="leaf" className="leaf" />
          <div className="farmland-info-content">
            <h2>Farmland sandalwood plantation</h2>
            <p>
              An opportunity regarding your farmland or agricultural land that
              may currently be lying idle. With the guidance of Royal Indian
              Sandalwood Private Limited, there is potential to transform this
              land into a lucrative asset through sandalwood plantation. Royal
              Indian Sandalwood Private Limited specializes in sustainable
              sandalwood cultivation, offering various financial models that
              could suit your specific needs. These models not only ensure a
              steady income stream but also contribute to environmental
              conservation and sustainable development. You can benefit from our
              expertise in managing sandalwood plantations, which are known for
              their high value in the market. This initiative not only enhances
              the value of your land but also contributes positively to the
              local economy and biodiversity.
            </p>
          </div>
          <img
            src={servicespage1}
            alt="Right Image"
            className="farmland-info-image"
          />
        </div>
      </div>
      <div className="farmland-info-bottom">
        <div className="farmland-info-bottom-left">
          <span>HAVE A LOOK</span>
          <h3>Plantation services booklet</h3>
        </div>
        <button
          className="learn-more-button"
          onClick={() => setShowBookFlip(true)}
        >
          Learn more
        </button>
      </div>
      <div className="farmland-faq-section">
        <img src={gift1} alt="faq-leaf" className="gift1" />
        <img src={gift2} alt="faq-leaf" className="gift2" />
        <h3 className="farmland-faq-title">Frequently Asked Questions</h3>
        <div className="farmland-faq-content">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className={`faq-item ${index === faqs.length - 1 ? "last" : ""}`}
            >
              <div className="faq-question">
                <p className="faq-toggle">{faq.question}</p>
                <FontAwesomeIcon
                  icon={openFaqIndex === index ? faChevronUp : faChevronDown}
                  className="faq-icon"
                  onClick={() => toggleFaq(index)}
                />
              </div>
              <span
                className={`faq-answer ${openFaqIndex === index ? "open" : ""}`}
              >
                {faq.answer}
              </span>
            </div>
          ))}
        </div>
      </div>
      <Footer showBothDivs={false} />

      {/* Popup for BookFlip */}
      {showBookFlip && (
        <div className="popup-overlay">
          <div className="popup-content">
            <button
              className="popup-close"
              onClick={() => setShowBookFlip(false)}
            >
              ×
            </button>
            <BookFlip />
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Farmland;
