import React from "react";
// import "./Responsibility.css";
import "./BookFlip.css";
import csr_1 from "../../Assets/CSR_1.jpg";
import csr_2 from "../../Assets/CSR_2.jpg";
import csr_3 from "../../Assets/CSR_3.jpg";
import csr_4 from "../../Assets/CSR_4.jpg";
import logo from "../../Assets/logo.png";

export const Responsibility = () => {
  return (
    <div className="book">
      <div className="left-respo-imges">
        <div className="row">
          <div className="column">
            <img
              src={csr_1}
              alt="csr-logo"
              style={{ width: "100%", height: "248px" }}
            />
          </div>
          <div className="column">
            <img
              src={csr_2}
              alt="hand-samll_plant"
              style={{ width: "100%", height: "248px" }}
            />
          </div>
        </div>
        <div className="row">
          <div className="column">
            <img
              src={csr_3}
              alt="plantation-hand-water-drop"
              style={{ width: "100%", height: "248px" }}
            />
          </div>
          <div className="column">
            <img
              src={csr_4}
              alt="chlid-plant-water"
              style={{ width: "100%", height: "248px" }}
            />
          </div>
        </div>
      </div>
      <div className="right-respo-info">
        <div>
          <h6
            style={{
              fontSize: "44px",
              fontWeight: "100",
            }}
          >
            CORPORATE <br />
            SOCIAL RESPONSIBILITY (CSR)
          </h6>
        </div>
        <div>
          <p style={{ marginTop: "20px" }}>
            Our company assists various NGO's in executing the plantation
            project as partners of the NGO The execution phase involves detailed
            research and feasibility study to understand the viability of
            sandalwood cultivation in the speciñc region. We evaluate factors
            like sof climate, water and market demand.
          </p>
        </div>
        <div className="text-respo-p">
          <p>
            <b>1. Training and Education:</b> We organize training programs and
            workshops for local farmers to esducate them about sandalwood
            farming techniques. This includes information on seed selection,
            planting, ingation, pest and disease management, and sustainable
            cultivation practices. Our company experts conduct these training
            sessions.
          </p>
        </div>
        <div className="text-respo-p">
          <p>
            <b>2. Technical Guidance and Monitoring:</b> We provide technical
            guidance and support to farmers. This involves regular visits by our
            agncul tural experts who offer advice on crop management, disease
            prevention, and other aspects of sandalwood farming.
          </p>
        </div>
        <div className="text-respo-p">
          <p>
            <b>3. Environmental Conservation:</b> We encourage farmers to
            implement agro forestry fechniques that promote biodiversity, anif
            protect local ecosystems. The contributes to a moni holistic appmach
            to CSR that benefits both the community and the environment.
          </p>
        </div>
        {/* <div className="info-respo-logo">
          <img src={logo} alt="logo" />
        </div> */}
      </div>
    </div>
  );
};
export default Responsibility;
