import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Function to refresh the access token
const refreshToken = async () => {
  const refresh_token = localStorage.getItem('refresh_token');
  try {
    const response = await axios.post('/auth/refresh', { refresh_token });
    const { access_token } = response.data;
    localStorage.setItem('access_token', access_token);
    return access_token;
  } catch (error) {
    console.error('Unable to refresh token', error);
    throw error;
  }
};

// Request interceptor to include the access token
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor to handle token expiration
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const newToken = await refreshToken();
      axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`;
      return axiosInstance(originalRequest);
    }
    return Promise.reject(error);
  }
);

const handleError = (error) => {
  if (error.response) {
    console.error('Error response:', error.response.data);
    console.error('Error status:', error.response.status);
    console.error('Error headers:', error.response.headers);
  } else if (error.request) {
    console.error('Error request:', error.request);
  } else {
    console.error('Error message:', error.message);
  }
  throw error;
};

export const get = async (url, params = {}, config = {}) => {
  try {
    const response = await axiosInstance.get(url, { params, ...config });
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const post = async (url, data, config = {}) => {
  try {
    console.log("url in post = ", post);
    const response = await axiosInstance.post(url, data, config);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const put = async (url, data, config = {}) => {
  try {
    const response = await axiosInstance.put(url, data, config);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const del = async (url, config = {}) => {
  try {
    const response = await axiosInstance.delete(url, config);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};
