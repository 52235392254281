import React from "react";
// import "./Plantation.css";
import "./BookFlip.css";
import Plantation_1 from "../../Assets/plantation_1.jpg";
import Plantation_2 from "../../Assets/plantation_2.jpg";
import Plantation_3 from "../../Assets/plantation_3.jpg";
import Plantation_4 from "../../Assets/plantation_4.jpg";
import Plantation_5 from "../../Assets/plantation_5.jpg";
import Plantation_6 from "../../Assets/plantation_6.jpg";
import logo from "../../Assets/logo.png";

export const Plantation = () => {
  return (
    <div className="book">
      <div className="left-plantation-img">
        <div className="row">
          <div className="column img-text">
            <div>
              <h3 style={{fontSize:"16px", fontWeight: "100", margin: "10px" }}>
                SOIL ANALYSIS
              </h3>
            </div>
            <div>
              <p style={{ fontSize: "9px", margin: "10px" }}>
                Our experts conduct thorough soil analy- sis to uncover its
                unique composition, fertility, overall health of soil, pH
                levels, and drainage characteristics.
              </p>
            </div>
          </div>
          <div className="column">
            <div>
              <img
                src={Plantation_1}
                alt="farm-land-man"
                style={{ width: "100%", height: "165px" }}
              />
            </div>
          </div>
          <div className="column">
            <div>
              <img
                src={Plantation_2}
                alt="laboratory-test"
                style={{ width: "100%", height: "165px" }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="column">
            <div>
              <img
                src={Plantation_3}
                alt="tractor-farming"
                style={{ width: "100%", height: "165px" }}
              />
            </div>
          </div>
          <div className="column">
            <img
              src={Plantation_4}
              alt="farm-land"
              style={{ width: "100%", height: "165px" }}
            />
          </div>
          <div className="column img-text">
            <div>
            <h3 style={{fontSize:"16px", fontWeight: "100", margin: "10px" }}>
            SOIL PREPARATION
              </h3>
            </div>
            <div>
              <p style={{ fontSize: "9px", margin: "10px" }}>
                Proper soil preparation is essential for successful plant
                growth, and conducting a soil test beforehand can provide valu-
                able insights into the soil's nutrient content and pH levels.
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="column img-text">
            <div>
            <h3 style={{fontSize:"16px", fontWeight: "100", margin: "10px" }}>
            FERTILIZATION
              </h3>
            </div>
            <div>
              <p style={{ fontSize: "9px", margin: "10px" }}>
                Fertilization involves providing essential nutrients to the soil
                to promote healthy plant growth and maximize yields. This
                process replenishes the soil's nutrient content, which can
                become depleted over time due to previous crops, weath- ering,
                and other factors.
              </p>
            </div>
          </div>
          <div className="column">
            <div>
              <img
                src={Plantation_5}
                alt="farm-land-man"
                style={{ width: "100%", height: "165px" }}
              />
            </div>
          </div>
          <div className="column">
            <div>
              <img
                src={Plantation_6}
                alt="laboratory-test"
                style={{ width: "100%", height: "165px" }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="right-plantation-text">
        <div>
          <h6
            style={{ marginTop: "50px", fontSize: "70px", fontWeight: "100" }}
          >
            SANDALWOOD PLANTATION
          </h6>
        </div>
        <div>
          <p>
            A fragrant investment for sustainable growth. A sandalwood
            plantation offers a unique investment opportunity that combines
            envianihen Tal sustainability with potential financial gains.
            Sandalwood plantation sustainable benefits are:
          </p>
        </div>
        <div className="palnt-p-text">
          <p>
            <b>Economic Value:</b> The increasing global demand for sandalwood
            products presents an opportunity for investors to earn substantial
            retums.
          </p>
        </div>
        <div className="palnt-p-text">
          <p>
            <b>Environmental Benefits:</b> As slow-growing trees, sandalwood
            promotes long-term carbon restoration, aiding in climate change
            mitigation.
          </p>
        </div>
        <div className="palnt-p-text">
          <p>
            <b>Biodiversity Support:</b> These plantations can provide habitats
            for various plant and animal species, enhancing local biodiversity.
          </p>
        </div>
        {/* <div className="info-logo">
          <img src={logo} alt="logo" />
        </div> */}
      </div>
    </div>
  );
};
export default Plantation;
