import React from "react";
import "../../Styles/AboutPage.css";
import ramaiah from "../../Assets/ramaiah-chairman.png";
import chandrashekar from "../../Assets/chandrashekar-f-director.png";
import mohana from "../../Assets/Ravikumar-f-director.png";
import shetty from "../../Assets/Rajan-m-director.png";

const AboutPages = () => {
  return (
    <>
      <div className="flex-container-mission">
        <div className="flex-slide-mission mission-section">
          <div className="flex-title-mission">Mission</div>
          <div className="flex-about-mission">
            <p>
              The company shall promote environmentally appropriate, socially
              beneficial and economically viable sandalwood services and
              products management.
            </p>
          </div>
        </div>
        <div className="flex-slide-mission vision-section">
          <div className="flex-title-mission">Vision</div>
          <div className="flex-about-mission">
            <p>
              When anyone thinks of sandalwood and its products, the only name
              that should immediately come to mind is ROYAL INDIAN SANDALWOOD
              PRIVATE LIMITED.
            </p>
          </div>
        </div>
      </div>
      <div className="team-info">
        <h6>OUR CORE TEAM</h6>
        <div className="team-cards">
          <div className="cardd">
            <div className="card-info">
              <img src={ramaiah} alt="Kondandaram Ramaiah" />
              <div className="card-content">
                <h4>Kondandaram Ramaiah</h4>
                <ul>
                  <li>Chairman</li>
                </ul>
              </div>
            </div>
            <hr />
            <p >
              Promoter and Managing Director of Ramalah Capital Private Limited.
              He is the son of Late Dr. MS Ramah, the well-known education
              visionary and entrepreneur of Karnataka who set up the Ramalah
              Group of Educational Institutions. An entrepreneur, running
              numerous business entities such as MS Ramaiah International Pvt
              Ltd, MSR Chemicals & Fertilizers Pvt. Ltd., MSR Biotech Pvt Ltd.
              He is also the Director of M/s. Ramaiah Educational Institutions
              and advisor to Gokula Education Foundation.
            </p>
          </div>
          <div className="cardd">
            <div className="card-info">
              <img src={chandrashekar} alt="Dr. KT Chandrashekar" />
              <div className="card-content">
                <h4>Dr. KT Chandrashekar</h4>
                <ul>
                  <li>Founder Director</li>
                </ul>
              </div>
            </div>
            <hr />
            <p >
              Master in Sandalwood anatomy and a specialist in forest botany for
              30+ years. He is one of those masters who have been part of the
              entire sandalwood journey from plantation to maintenance to
              harvesting. "SRIGANDHAVANA" - Awarded by the Chief Minister of
              Karnataka for Lifetime achievement in Sandalwood research and
              development.
            </p>
          </div>
          <div className="cardd">
            <div className="card-info">
              <img src={mohana} alt="Dr. P Mohana" />
              <div className="card-content">
                <h4>Dr. P Mohana</h4>
                <ul>
                  <li>Executive Director</li>
                </ul>
              </div>
            </div>
            <hr />
            <p >
              The Master Sandalwood researcher and a specialist in the chemistry
              of forestry products for 30+ years. His expertise in the field of
              Sandalwood aromatics and cosmetic products is a big contribution
              to our company. Sandalwood oil extraction and distillation
              specialist who possesses a rare skill set specifically tailored to
              the intricate process of distilling Sandalwood seed oil. He has
              published 120 research articles, 2 books, and 5 brochures.
              "SANTALUM AWARD" - Lifetime Achievement award for contribution in
              sandalwood research and development.
            </p>
          </div>
          <div className="cardd">
            <div className="card-info">
              <img src={shetty} alt="Rajan B Shetty" />
              <div className="card-content">
                <h4>Rajan B Shetty</h4>
                <ul>
                  <li>CEO and Managing Director</li>
                </ul>
              </div>
            </div>
            <hr />
            <p >
              An engineer by qualification and senior management personnel from
              a very reputed MNC who has served in various senior management
              roles for a span of 24 years. A visionary entrepreneur who has
              embraced the role of a sandalwood farmer. His journey is a
              testament to his belief in carving out a unique and unparalleled
              path. Sandalwood farmer specializing in the entire life cycle of
              Sandalwood from seed to pest management and owns 1000+ sandalwood
              trees.
            </p>
          </div>
        </div>
      </div>
      <div className="why-choose-us">
        <h2>Why Choose Us?</h2>
        <ul className="why-choose-details">
          <li>
            Royal Indian Sandalwood with its decades of experience in the
            industry, has honed its expertise and garnered a deep understanding
            of Indian sandalwood cultivation and processing.
          </li>
          <li>
            Royal Indian Sandalwood has consistently adhered to stringent
            quality standards, ensuring that its products and services meet the
            highest benchmarks of excellence. The company's commitment to
            quality is reflected in every aspect of its operations, from seed
            selection and plantation practices to harvesting, extraction, and
            final product development.
          </li>
          <li>
            Drawing upon its vast experience, the company has fine-tuned its
            cultivation techniques, leveraging traditional wisdom and
            incorporating modern advancements to optimize the growth and yield
            of Indian sandalwood. This wealth of experience has also enabled
            Royal Indian Sandalwood to navigate challenges effectively and adapt
            to changing market dynamics, cementing its position as a trusted and
            reliable industry leader.
          </li>
          <li>
            The uncompromising stance on quality and the extensive experience
            have not only earned the company a solid reputation among its
            clients but also garnered respect from peers and stakeholders within
            the sandalwood sector. Royal Indian Sandalwood stands as a testament
            to the enduring value of expertise, dedication, and a relentless
            pursuit of excellence in the domain of Indian sandalwood solutions.
          </li>
          <li>We produce 100% natural products.</li>
          <li>We earn your respect while respecting deadlines and budget.</li>
          <li>
            We offer several models of financial income according to the short,
            medium and long-term objectives of each person.
          </li>
          {/* <li>Our solutions include services and products.</li> */}
        </ul>
      </div>
    </>
  );
};

export default AboutPages;
