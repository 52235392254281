import React from "react";
import { NavLink } from "react-router-dom";
import "./Footer.css";
import Foooter from "../../Assets/Footer.png";
import footer_mob from "../../Assets/footer_mob.png";
import Footer_logo from "../../Assets/Footerlogo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faFacebook,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";

export const Footer = ({ showBothDivs }) => {

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  
  return (
    <div className="Footer-main">
      {showBothDivs ? (
        <>
          <div className="Footer-img abc">
            <img className="footer_lg" src={Foooter} alt="img" />
            <img className="footer_sm" src={footer_mob} alt="img" />
            <h1>Join Sandalwood Society of India</h1>
          </div>

          <div className="content-footer">
            <div className="footer-logo">
              <img src={Footer_logo} alt="logo" />
            </div>

            <div className="footer-nav">
              <ul className="footer-nav-menu">
                <NavLink to="/" className="footer-navline1">
                  HOME
                </NavLink>
                <NavLink to="/about" className="footer-navline1">
                  ABOUT
                </NavLink>
                <NavLink to="/Services" className="footer-navline2">
                  SERVICES
                </NavLink>
                <NavLink to="/products" className="footer-navline1">
                  PRODUCTS
                </NavLink>
              </ul>
            </div>

            <div className="footer-social-media">
              <FontAwesomeIcon icon={faFacebook} />
              <FontAwesomeIcon icon={faInstagram} />
              <FontAwesomeIcon icon={faTwitter} />
            </div>

            <div className="end-footer">
              <p>&copy; 2024 RoyalIndian Sandalwood. All Rights Reserved</p>
            </div>
            <div className="footer-privacy-policy-container">
              <NavLink
                to="/privacy-policy"
                className="footer-privacy-policy-link"
                onClick={scrollToTop}
              >
                Privacy Policy
              </NavLink>
              <NavLink
                to="/Return-Refund-policy"
                className="footer-privacy-policy-link"
                onClick={scrollToTop}
              >
                Return & Refund Policy
              </NavLink>
              <NavLink
                to="/Terms-Conditions-policy"
                className="footer-Return-Refund-link"
                onClick={scrollToTop}
              >
                Terms and Conditions
              </NavLink>
            </div>
          </div>
        </>
      ) : (
        <div className="content-footer">
          <div className="footer-logo">
            <img src={Footer_logo} alt="logo" />
          </div>

          <div className="footer-nav">
            <ul className="footer-nav-menu d-flex">
              <NavLink to="/" className="footer-navline1">
                HOME
              </NavLink>
              <NavLink to="/about" className="footer-navline1">
                ABOUT
              </NavLink>
              <NavLink to="/Services" className="footer-navline2">
                SERVICES
              </NavLink>
              <NavLink to="/products" className="footer-navline1">
                PRODUCTS
              </NavLink>
            </ul>
          </div>

          <div className="footer-social-media">
            <FontAwesomeIcon icon={faFacebook} />
            <FontAwesomeIcon icon={faInstagram} />
            <FontAwesomeIcon icon={faTwitter} />
          </div>

          <div className="end-footer">
            <p>&copy; 2024 RoyalIndian Sandalwood. All Rights Reserved</p>
          </div>

          <div className="footer-privacy-policy-container">
            <NavLink
              to="/privacy-policy"
              className="footer-privacy-policy-link"
              onClick={scrollToTop}
            >
              Privacy Policy
            </NavLink>
            <NavLink
              to="/Return-Refund-policy"
              className="footer-privacy-policy-link"
              onClick={scrollToTop}
            >
              Return & Refund Policy
            </NavLink>
            <NavLink
              to="/Terms-Conditions-policy"
              className="footer-Return-Refund-link"
              onClick={scrollToTop}
            >
              Terms and Conditions
            </NavLink>
          </div>
        </div>
      )}
    </div>
  );
};

export default Footer;
