import React, { useState, useEffect } from "react";
import UserMenu from "../../Components/Layout/UserMenu";
import Layout from "./../../Components/Layout/Layout";
import Footer from "../../Components/Layout/Footer";
import axios from "axios";
import { useAuth } from "../../Context/Auth";
import moment from "moment";
import "./css/Orders.css";

const Orders = () => {
  const [cartItems, setCartItems] = useState([]);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const accessToken = "1";

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(
          "https://rispl.backendapihub.com/order/history",
          {
            headers: {
              accept: "application/json",
              "access-token": accessToken,
            },
          }
        );
        if (response.data.status === "Success") {
          setOrders(response.data.data);
        } else {
          setError(response.data.message);
        }
      } catch (err) {
        setError("An error occurred while fetching orders.");
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  const statusColor = (status) => {
    if (status === "Delivered") {
      return "#0B7D0F";
    } else if (status === "Returned") {
      return "#FF6B00";
    } else if (status === "Cancelled") {
      return "#FF0000";
    } else {
      return "#000000";
    }
  };
  // setOrders(mockOrders);

  // if (loading) {
  //   return <p>Loading...</p>;
  // }

  // if (error) {
  //   return <p>Error: {error}</p>;
  // }

  return (
    <Layout
      // title={"Your Orders"}
      setCartItems={setCartItems}
      cartItems={cartItems}
      isFooter={true}
      isNavbar={true}
    >
      <div className="dash-top-section"></div>
      <div className="dash-container">
        <div className="d-flex flex-lg-nowrap userActflexx">
          <UserMenu />
          <div className="orc">
            <h1 className="text-start p-0">Orders history</h1>
            <div className="orderCardList">
              <div className="itemsContainer">
                {orders.length > 0 ? (
                  orders.map((order) => (
                    <div
                      key={order.orderID}
                      className="orderCardItem d-flex align-items-center"
                    >
                      <img src={order.mediaName} alt={order.productName} />
                      <div className="itemContaint">
                        <div className="containtOne d-flex flex-wrap align-items-center">
                          <h3 className="m-0 p-0">{order.productName}</h3>
                          <div className="orderRigtContain d-flex align-items-center">
                            <div className="orderPrice">
                              <p className="m-0 p-0">Price</p>
                              <span>₹ {order.price?.toFixed(2) || "0.00"}</span>
                            </div>
                            <div className="orderQuantity">
                              <p className="m-0 p-0">Quantity</p>
                              <span>{order.quantity || "0"}</span>
                            </div>
                            <div className="orderTotal">
                              <p className="m-0 p-0">Total</p>
                              <span>₹ {order.total?.toFixed(2) || "0.00"}</span>
                            </div>
                          </div>
                        </div>
                        <hr className="m-0 horizontalLine" />
                        <div className="containtTwo d-flex align-items-center gap-3">
                          <p className="m-0">
                            Order date:
                            <span className="ps-1">{order.orderDate}</span>
                          </p>
                          <hr className="m-0" />
                          <p className="m-0">
                            Order time:
                            <span className="ps-1">{order.orderTime}</span>
                          </p>
                          <hr className="m-0" />
                          <p className="m-0">
                            Status:
                            <span
                              className="ps-1"
                              style={{ color: statusColor(order.status) }}
                            >
                              {order.status}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No orders found</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Orders;
