import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import "../Styles/ReturnRefundPolicy.css";
import Layout from "../Components/Layout/Layout";
import Footer from "../Components/Layout/Footer";

const ReturnRefundPolicy = () => {
    const location = useLocation();
    const [cartItems, setCartItems] = useState(location.state?.cartItems || []);

  return (
    <Layout setCartItems={setCartItems} cartItems={cartItems} isNavbar={true}>
      <div className="return-refund-policy">
        <h1>Return and Refund Policy</h1>
        <p>
          Thank you for shopping with us! We take pride in offering high-quality
          sandalwood products and want to ensure you are completely satisfied
          with your purchase. If for any reason you are not, please review our
          return and refund policy below.
        </p>

        <h2>1. Return Eligibility</h2>
        <ul>
          <li>
            <strong>Time Frame:</strong> Returns are accepted within 3 days from
            the date of delivery.
          </li>
          <li>
            <strong>Condition:</strong> Products must be returned in their
            original, unused, and undamaged condition. Items should be in their
            original packaging with all tags and labels intact.
          </li>
          <li>
            <strong>Exclusions:</strong> Due to the nature of our products, we
            cannot accept returns on items that have been used, altered, or
            damaged. Items marked as “Final Sale” are not eligible for returns.
          </li>
        </ul>

        <h2>2. Return Process</h2>
        <ul>
          <li>
            <strong>Initiate a Return:</strong> To start a return, please
            contact our Customer Service team at{" "}
            <a href="mailto:info@royalindiansandalwood.com">
              info@royalindiansandalwood.com
            </a>{" "}
            or +91 9004015163 to obtain a Return Authorization (RA) number.
          </li>
          <li>
            <strong>Return Shipping:</strong> Once you have your RA number,
            securely package the item and include a copy of your original
            receipt or order confirmation. The return shipping costs are the
            responsibility of the customer unless the return is due to a defect
            or error on our part.
          </li>
          <li>
            <strong>Address:</strong> Ship the return to the following address:
            <p>
              “ROYAL INDIAN SANDALWOOD PRIVATE LIMITED”
              <br />
              Ward 9, Block 65 2, Near Jindhal Srikanthapura, Bengaluru 560073,
              Karnataka, India.
              <br />
              Email:{" "}
              <a href="mailto:info@royalindiansandalwood.com">
                info@royalindiansandalwood.com
              </a>
              <br />
              Contact number: +91 9004015163
            </p>
          </li>
          <li>
            <strong>Tracking:</strong> We recommend using a trackable shipping
            service or purchasing shipping insurance to ensure that the return
            is received. We are not responsible for lost or damaged return
            shipments.
          </li>
        </ul>

        <h2>3. Refund Process</h2>
        <ul>
          <li>
            <strong>Processing Time:</strong> Refunds will be processed within
            10-15 business days after we receive the returned item. The refund
            will be issued to the original method of payment.
          </li>
          <li>
            <strong>Partial Refunds:</strong> In cases where only part of an
            order is returned, the refund will be adjusted to reflect the
            remaining items. Shipping and handling fees are non-refundable.
          </li>
        </ul>

        <h2>4. Defective or Incorrect Items</h2>
        <ul>
          <li>
            <strong>Defects:</strong> If you receive a defective or incorrect
            item, please contact us immediately at{" "}
            <a href="mailto:info@royalindiansandalwood.com">
              info@royalindiansandalwood.com
            </a>{" "}
            or +91 9004015163 with your order number and a description of the
            issue. We will arrange for a replacement or a full refund, including
            shipping costs.
          </li>
          <li>
            <strong>Proof of Defect:</strong> Please provide clear photos of the
            defect or issue to assist in the resolution process.
          </li>
        </ul>

        <h2>5. Exchanges</h2>
        <p>
          <strong>Exchange Policy:</strong> We currently do not offer direct
          exchanges. If you need a different product, please return the original
          item for a refund and place a new order for the replacement item.
        </p>

        <h2>6. Cancellation</h2>
        <p>
          <strong>Cancellation Policy:</strong> Below is our cancellation policy
          for orders placed on our website.
        </p>
        <ul>
          <li>
            <strong>Before Shipping:</strong> You can cancel your order free of
            charge within 8 hours of placing it. Please contact our Customer
            Service team as soon as possible to request a cancellation.
          </li>
          <li>
            <strong>After Shipping:</strong> Once an order has been shipped, we
            are unable to cancel it. If you wish to return the product, please
            refer to our Return and Refund Policy for instructions on how to
            proceed.
          </li>
        </ul>
        <p>
          <strong>How to Cancel an Order:</strong> To cancel your order, please
          reach out to our Customer Service team at{" "}
          <a href="mailto:info@royalindiansandalwood.com">
            info@royalindiansandalwood.com
          </a>{" "}
          or +91 9004015163 with your order number and cancellation request.
        </p>
        <p>
          <strong>Confirmation:</strong> You will receive a confirmation email
          once your cancellation has been processed. If the order has already
          been shipped, you will need to follow our return process to get a
          refund.
        </p>
        <ul>
          <li>
            <strong>Custom or Special Orders:</strong> Orders for custom or
            special items may not be eligible for cancellation once they are in
            production. Please review the product details and confirm your order
            before placing it.
          </li>
        </ul>

        <h2>7. Contact Us</h2>
        <p>
          For any questions or concerns regarding returns and refunds, please
          reach out to our Customer Service team at:
        </p>
        <ul>
          <li>
            Email:{" "}
            <a href="mailto:info@royalindiansandalwood.com">
              info@royalindiansandalwood.com
            </a>
          </li>
          <li>Phone: +91 9004015163</li>
          <li>
            Business Hours:
            <br />
            Monday to Friday: 10 am to 6 pm.
          </li>
        </ul>

        <h2>8. Changes to This Policy</h2>
        <p>
          We reserve the right to update or modify this policy at any time. Any
          changes will be posted on our website and will apply to orders placed
          after the date of the update.
        </p>
      </div>
      <Footer showBothDivs={false} />
    </Layout>
  );
};

export default ReturnRefundPolicy;
