import React, { useState } from "react";
import "../../Styles/Villa.css";
import villa1 from "../.././Assets/villa1.png";
import villa2 from "../.././Assets/villa2.png";
import gift1 from "../.././Assets/gift-faq-one.png";
import gift2 from "../.././Assets/gift-faq-two.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import Layout from "../.././Components/Layout/Layout";
import Footer from "../.././Components/Layout/Footer";

const Villa = () => {
  const [cartItems, setCartItems] = useState([]);
  const [openFaqIndex, setOpenFaqIndex] = useState(null);

  const toggleFaq = (index) => {
    setOpenFaqIndex(openFaqIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "1. Can you tell us the forte of your company?",
      answer:
        "The strength of our company lies upon its core values in terms of ethics, integrity, trust and loyalty towards our customer.",
    },
    {
      question: "2. Where is your company located?",
      answer:
        "The strength of our company lies upon its core values in terms of ethics, integrity, trust and loyalty towards our customer.",
    },
    {
      question:
        "3. Which all part of the country does your company provide its services?",
      answer:
        "The strength of our company lies upon its core values in terms of ethics, integrity, trust and loyalty towards our customer.",
    },
    {
      question: "4. Which sandalwood does your company deal with?",
      answer:
        "The strength of our company lies upon its core values in terms of ethics, integrity, trust and loyalty towards our customer.",
    },
    {
      question: "5. What are the different names of sandalwood?",
      answer:
        "The strength of our company lies upon its core values in terms of ethics, integrity, trust and loyalty towards our customer.",
    },
    {
      question: "6. Is red sandalwood and yellow sandalwood same?",
      answer:
        "The strength of our company lies upon its core values in terms of ethics, integrity, trust and loyalty towards our customer.",
    },
    {
      question:
        "7. What are the main differences between red and yellow sandalwood?",
      answer:
        "The strength of our company lies upon its core values in terms of ethics, integrity, trust and loyalty towards our customer.",
    },
  ];

  return (
    <Layout setCartItems={setCartItems} cartItems={cartItems} isNavbar={true} textColor="white" >
      <section className="villa-section">
        <div className="text-overlay">
          <h1>
            <span className="textt-1">Villa/Bungalow</span>{" "}
            <span className="textt-2">Sandalwood Plantation</span>
          </h1>
        </div>
      </section>
      <section className="details-section">
        <div className="details-content">
          <div className="details-text">
            <h2>Villa/Bungalow Sandalwood Plantation</h2>
            <p>
              At “ROYAL INDIAN SANDALWOOD PRIVATE LIMITED”, we believe in
              creating spaces that blend luxury with sustainability, where every
              element serves a purpose beyond aesthetics. Our latest initiative
              involves planting sandalwood trees around individual bungalows,
              redefining luxury living while contributing to a greener, more
              sustainable future. Our vision is to create harmonious
              environments where residents can experience the timeless elegance
              of sandalwood while contributing to environmental conservation.
              Through meticulous planning and sustainable practices, we aim to
              establish thriving sandalwood plantations that enrich lives and
              inspire stewardship of our natural resources. Our commitment to
              sustainability extends to sites that promote biodiversity and
              environmental balance, creating havens of tranquility and natural
              beauty.
            </p>
          </div>
          <div className="details-image">
            <img src={villa1} alt="villa Sandalwood Plantation" />
          </div>
        </div>
        <div className="additional-content">
          <div className="additional-content-layer">
            <h3>Design of the Landscape</h3>
            <p>
              The design of the landscape surrounding the bungalows is
              meticulously crafted to integrate sandalwood trees seamlessly.
              Walkways adorned with fragrant flora lead to serene corners where
              residents can connect with nature. Every aspect of the layout is
              curated to enhance well-being and foster a sense of connection
              with the environment. We employ cutting-edge techniques in
              sandalwood cultivation, leveraging expertise and innovation to
              ensure the health and vitality of each tree. Our dedicated team of
              professionals oversees every stage of growth, from planting to
              sustainable harvesting, adhering to best practices and ethical
              standards. The plantation of sandalwood trees offers dual benefits
              - economic and environmental. Residents not only enjoy the luxury
              of living amidst these revered trees but also contribute to a
              sustainable economy through potential wood and oil production.
              Moreover, the trees play a vital role in carbon sequestration,
              soil conservation, and biodiversity enhancement, aligning with our
              commitment to environmental stewardship. Planting sandalwood
              around your bungalows is a testament to our ethos of responsible
              luxury and environmental consciousness. We invite you to be part
              of this journey, where luxury meets sustainability, and where
              every tree planted is a step towards a greener, more harmonious
              world.
            </p>
            <img src={villa2} alt="villa" className="landscape-image" />
          </div>
        </div>
      </section>
      <section className="economies-section">
        <div className="economies-container">
          <div className="economies-content">
            <div className="economies-left">
              <h2>Economies of Villa / Bungalow Sandalwood Plantation</h2>
            </div>
            <div className="economies-right">
              <button className="experience-button">
                Experience Our Service
              </button>
            </div>
          </div>
          <div className="economies-details">
            <div className="economies-detail">
              <h3>
                Best Sandalwood <br />
                Saplings
              </h3>
              <p className="p1">
                We meticulously choose{" "}
                <span>10 - 15 best sandalwood saplings </span>from our nursery
                and plant them strategically around your bungalow using
                scientific methods and skilled laborers trained to professional
                standards.
              </p>
            </div>
            <div className="economies-detail">
              <h3>We offer service at a modest price</h3>
              <p className="p2">
                We offer our services at a modest price of{" "}
                <span>Rs. 15,000, inclusive of GST </span>
                of GST, covering both the plantation process and ongoing
                support. The above price also covers the plantation of host
                plants essential for promoting the healthy growth of sandalwood.
              </p>
            </div>
            <div className="economies-detail">
              <h3>Appreciating in value over time</h3>
              <p className="p3">
                Sandalwood stands out by appreciating in value over time, unlike
                everything else which typically depreciates,{" "}
                <span>making it a potential multibagger</span>.
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="farmland-Faq-section">
        <img src={gift1} alt="Faq-leaf" className="giftt1" />
        <img src={gift2} alt="Faq-leaf" className="giftt2" />
        <h3 className="farmland-Faq-title">Frequently Asked Questions</h3>
        <div className="farmland-Faq-content">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className={`Faq-item ${index === faqs.length - 1 ? "last" : ""}`}
            >
              <div className="Faq-question">
                <p className="Faq-toggle">{faq.question}</p>
                <FontAwesomeIcon
                  icon={openFaqIndex === index ? faChevronUp : faChevronDown}
                  className="Faq-icon"
                  onClick={() => toggleFaq(index)}
                />
              </div>
              <span
                className={`Faq-answer ${openFaqIndex === index ? "open" : ""}`}
              >
                {faq.answer}
              </span>
            </div>
          ))}
        </div>
      </div>
      <Footer showBothDivs={false} />
    </Layout>
  );
};

export default Villa;
