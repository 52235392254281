import React, { useState } from "react";
import Layout from "../Components/Layout/Layout";
import BookFlip from "../Components/Sub_Home/BookFlip";
import ServicesPage from "../Components/Sub_Home/ServicesPage";
import PlantationServices from "../Components/Sub_Home/PlantationService";
import Farmland from "../Components/Sub_Home/Farmland";
import Villa from "../Components/Sub_Home/Villa";
import ProductssPage from "../Components/Sub_Home/ProductsPage";

const ServicePage = () => {
  const [cartItems, setCartItems] = useState([]);

  return (
    <Layout setCartItems={setCartItems} cartItems={cartItems} isFooter={true} isNavbar={true}> 
      <div className="about-main" style={{ marginTop: 20 }}>
        {/* <BookFlip /> */}
        <ServicesPage/>
        {/* <PlantationServices/> */}
        {/* <Farmland/> */}
        {/* <Villa/> */}
        {/* <ProductssPage/> */}
      </div>
    </Layout>
  );
};

export default ServicePage;
