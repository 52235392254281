import {post} from './common/apiService';

export const sendORVerifyOtp = (
    sendOTP_verifyOTP,
    type,
    email,
    mobile,
    otp
) => {
    const data = {
        "email": email,
        "mobile": mobile,
        "otp": otp
    }

    return post(`/auth/${sendOTP_verifyOTP}/${type}`, data);
}

export const guestIP = (ipAddress) => {
    const data = {
        "ipaddress": ipAddress
    }
    return post("/auth/guestip", data)
}

export const registerUser = (
    name,
    mobile,
    otp,
    email,
    password
) => {

    const data = {
        "name": name,
        "mobile": mobile,
        "otp": otp,
        "email": email,
        "password": password
    }

    return post("/user/register", data);
}

export const loginUser = (
    email_mobile, 
    otp_password,
    email,
    mobile,
    password,
    otp
) => {
    const data = {
        "email": email,
        "mobile": mobile,
        "password": password,
        "otp": otp
    }

    return post(`/user/login/${email_mobile}/${otp_password}`, data);
}

export const sendVerifyOtp = (
    registerOTP_forgotPasswordOTP,
    sendOTP_verifyOTP,
    mobile,
    otp
) => {
    const data = {
        "mobile": mobile,
        "otp": otp
    }

    return post(`/user/otp/${registerOTP_forgotPasswordOTP}/${sendOTP_verifyOTP}`, data);
}

export const updatePassword = (password) => {
    const data = {
        "password": password
    }
    return post("/user/updatepassword", data);
}

export const updateProfile = (userId) => {
    return post(`/user/updateprofile/${userId}`);
}

export const viewProfile = (userId) => {
    return post(`/user/viewprofile/${userId}`);
}