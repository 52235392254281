import React from "react";
// import "./AboutUs.css";
import "./BookFlip.css";
import hand_plant from "../../Assets/hand-plant.jpg";
import logo from "../../Assets/logo.png";

export const AboutUs = () => {
  return (
    <div className="book">
      <div className="left-img">
        <img src={hand_plant} alt="hand-plant" />
      </div>
      <div className="right-text">
        <div>
          <h6 style={{ fontSize: "30px", fontWeight: "100" }}>ABOUT US</h6>
        </div>
        <div>
          <p>
            <b>The Pioneering Company:</b> We are a leading Indian company
            committed to offer reliable and eco-friendly services and products
            within the domain of Indian sandalwood. We offer all-encompassing
            services for the management of Indian sandalwood, which include
            activities ranging from plantation, cultivation, harvesting,
            maintenance, training and more.
          </p>
        </div>
        <div>
          <p>
            <b>The Legacy of Sandalwood Industry Veteran Scientists:</b> We are
            a team of sandalwood industry veteran scientists with a rich
            experience of over 30 years in planting, nurturing, studying,
            researching, and documenting the complete lifecycle of sandalwood.
            Our extensive knowledge, expertise and unwavering dedication, has
            elevated the standards of sandalwood cultivation and ensured the
            highest quality products.
          </p>
        </div>
        <div>
          <p>
            Commitment to Excellence and Quality: The company's core values
            revolve around excellence, commitment, communication, and loyal ty
            toward our customers. By setting stringent quality standards, we
            guarantee that only the finest Indian sandalwood reaches the market
            From seed selection to plantation management, every aspect of the
            process adheres to meticulous quality control measures. This commit
            ment to excellence has earned our company a reputation as a trusted
            and reliabile source of premium sandalwood.
          </p>
        </div>
        <div>
          <p>
            <b>Building Trust through Ethics and Integrity:</b> Ethics,
            integrity, and aptitude form the foundation of our company's
            operations. We priore tize fair and transparent business practices,
            ensuring that our customers receive genuine sandalwood products. By
            fostering long-term relationships based on trust, we have become the
            go-to choice for individuals and businesses seeking reliable
            sandalwood solutions.
          </p>
        </div>
        <div>
          <p>
            <b>Mission:</b> The company shall promote environmentally
            appropriate, socially beneficial and economically viable sandalwood
            services and products management.
          </p>
        </div>
        <div>
          <p>
            <b>Vision:</b> To meet the present generations social ecological and
            economic rights and needs without jeopardizing anything of the next
            generation.
          </p>
        </div>
        {/* <div className="info-logo">
          <img src={logo} alt="logo" />
        </div> */}
      </div>
    </div>
  );
};
export default AboutUs;
