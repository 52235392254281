import React from "react";
// import "./Consultancy.css";
import "./BookFlip.css";
import consultancy_1 from "../../Assets/consultancy_1.jpg";
import consultancy_2 from "../../Assets/consultancy_2.jpg";
import consultancy_3 from "../../Assets/consultancy_3.jpg";
import consultancy_4 from "../../Assets/consultancy_4.jpg";
import logo from "../../Assets/logo.png";

export const Consultancy = () => {
  return (
    <div className="book">
      <div className="left-cons-imges">
        <div className="row">
          <div className="column">
            <img
              src={consultancy_1}
              alt="farming-tree"
              style={{ width: "100%", height: "248px" }}
            />
          </div>
          <div className="column">
            <img
              src={consultancy_2}
              alt="sandalwood-pest-management"
              style={{ width: "100%", height: "248px" }}
            />
          </div>
        </div>
        <div className="row">
          <div className="column">
            <img
              src={consultancy_3}
              alt="security-hand-coin"
              style={{ width: "100%", height: "248px" }}
            />
          </div>
          <div className="column">
            <img
              src={consultancy_4}
              alt="tree-guard"
              style={{ width: "100%", height: "248px" }}
            />
          </div>
        </div>
      </div>
      <div className="right-cons-info">
        <div>
          <h6
            style={{
              fontSize: "44px",
              fontWeight: "100",
            }}
          >
            SANDALWOOD CONSULTANCY (ADVISORY)
          </h6>
        </div>
        <div className="text-cons-p">
          <p style={{ marginTop: "10px" }}>
            <b>SANDALWOOD FARMING:</b> Our consultancy in Sandalwood farming
            involves sharing knowledge and expertise related to various aspects
            of sandalwood cultivation, with the aim of helping dients maximize
            their success in growing healthy sandalwood trees and ultimately
            obtaining a profitable yield of high-quality sandalwood.
          </p>
        </div>
        <div className="text-cons-p">
          <p>
            <b>SANDALWOOD PEST MANAGEMENT:</b> Sandalwood pest management is a
            critical aspect of cultivating sandalwood trees, which are primarily
            valued for their fragrant heartwood used in the production of
            high-end perfumes, incense, and other aromatic products. Effec tive
            pest management is essential to ensure the health and productivity
            of sandalwood plantations.
          </p>
        </div>
        <div className="text-cons-p">
          <p>
            <b>SANDALWOOD SECURITY:</b> Setting up security solutions for a
            sandalwood farm involves ensuring the protection of valuable assets
            and preventing unauthorized access. The company provides various
            security solutions to the customer depending on his immediate need
            and financial state. All solutions provided are state of the art and
            best in the industry.
          </p>
        </div>
        <div className="text-cons-p">
          <p>
            <b>SANDALWOOD INCOME FINANCIAL MODELS:</b> Our company has created
            diverse financial income models tailored to meet the custom- er's
            short-term, medium-term, and long-term financial requirements.
          </p>
        </div>
        {/* <div className="info-cons-logo">
          <img src={logo} alt="logo" />
        </div> */}
      </div>
    </div>
  );
};
export default Consultancy;
