import React, { useState } from "react";
import "../Styles/Productpagemain.css";
import bubble_1 from "../Assets/bubble1.png";
import bubble_3 from "../Assets/bubble3.png";
import perlimg from "../Assets/pearl.png";
import r_leaf from "../Assets/r_leaf.png";
import Service_plantation1 from "../Assets/Service_plantation1.png";
import ALL_products_img from "../Assets/ALL-products_img.png";
import PRoduct1 from "../Assets/PRoduct1.png";
import PRoduct2 from "../Assets/PRoduct2.png";
import PRoduct3 from "../Assets/PRoduct3.png";
import plant from "../Assets/hand-plant.jpg";
import Layout from "../Components/Layout/Layout";
import Footer from "../Components/Layout/Footer";
import { useNavigate } from "react-router-dom";

const Productpagemain = () => {
  const [cartItems, setCartItems] = useState([]);
  const navigate = useNavigate();

  const handleBannerClick = () => {
    window.scrollTo(0, 0);
    navigate(`/Gifting`);
  };

  const handleClick = () => {
    window.scrollTo(0, 0);
    navigate(`/productlist`);
    window.location.reload();
  };

  const handleClick2 = () => {
    window.scrollTo(0, 0);
    navigate(`/productlist`);
    window.location.reload();
  };

  return (
    <Layout setCartItems={setCartItems} cartItems={cartItems} isNavbar={true}>
      <div className="Productpagemain-Plantation-bg row position-relative">
        {/* <span>
          <img className="peerl-imgs d-md" src={perlimg} alt="" />
        </span>
        <span>
          <img className="r-Leaff d-md" src={r_leaf} alt="" />
        </span> */}
        {/* <img className="bubbllee" id="bubblleonee" src={bubble_3} alt="" /> */}
        {/* <img className="bubbllee" id="bubbllefourr" src={bubble_1} alt="" /> */}
      </div>
      <div className="Productpagemain-section">
        <div className="Productpagemain-container">
          <img
            className="Productpagemain-image"
            src={ALL_products_img}
            alt="Farmland"
          />
          <div className="Productpagemain-text-container">
            <h2 className="Productpagemain-title">All Products</h2>
            <p className="Productpagemain-description">
              An opportunity regarding your farmland or agricultural land that
              may currently be lying idle. With the guidance of Royal Indian
              Sandalwood Private Limited.
            </p>
            <button className="know-more-btn" onClick={handleClick2} style={{backgroundColor:"rgba(237, 189, 103, 1)",border:"none"}}>
              Shop Now
            </button>
          </div>
        </div>
        <div className="Productpagemain-container">
          <img
            className="Productpagemain-image"
            src={Service_plantation1}
            alt="Farmland"
          />
          <div className="Productpagemain-text-container">
            <h2 className="Productpagemain-title">Har ghar chandan</h2>
            <p className="Productpagemain-description">
              An opportunity regarding your farmland or agricultural land that
              may currently be lying idle. With the guidance of Royal Indian
              Sandalwood Private Limited.
            </p>
            <button className="know-more-btn" onClick={handleBannerClick} style={{backgroundColor:"rgba(237, 189, 103, 1)",border:"none"}}>
              Shop Now
            </button>
          </div>
        </div>
        <div className="Productpagemain-container">
          {/* <div className="coming-soon-overlay">
            <p className="coming-soon-text">Coming Soon..</p>
          </div> */}
          <img className="Productpagemain-image" src={plant} alt="Villa" />
          <div className="Productpagemain-text-container">
            <h2 className="Productpagemain-title">
              Gift a sandalwood plant
            </h2>
            <p className="Productpagemain-description">
              Gift a sandalwood plant and contribute to a greener future,
              providing a unique and valuable present that grows in both
              significance and worth.
            </p>
            <button className="know-more-btn" disabled>
              Coming Soon
            </button>
          </div>
        </div>
        <div className="Productpagemain-container">
          {/* <div className="coming-soon-overlay">
            <p className="coming-soon-text">Coming Soon..</p>
          </div> */}
          <img
            className="Productpagemain-image"
            src={PRoduct2}
            alt="Corporate Gifting"
          />
          <div className="Productpagemain-text-container">
            <h2 className="Productpagemain-title">
              Unique gifting Sandalwood products
            </h2>
            <p className="Productpagemain-description">
              Impress clients and partners with premium sandalwood products,
              offering a touch of elegance and lasting value in your Unique
              gifts.
            </p>
            <button className="know-more-btn" onClick={handleClick} disabled>
              Coming Soon
            </button>
          </div>
        </div>
        <div className="Productpagemain-container">
          {/* <div className="coming-soon-overlay">
            <p className="coming-soon-text">Coming Soon..</p>
          </div> */}
          <img className="Productpagemain-image" src={PRoduct3  } alt="Villa" />
          <div className="Productpagemain-text-container">
            <h2 className="Productpagemain-title">Customizable Handicrafts</h2>
            <p className="Productpagemain-description">
              An opportunity regarding your farmland or agricultural land that
              may currently be lying idle. With the guidance of Royal Indian
              Sandalwood Private Limited.
            </p>
            <button className="know-more-btn" disabled>
              Coming Soon
            </button>
          </div>
        </div>
        <div className="Productpagemain-container">
          {/* <div className="coming-soon-overlay">
            <p className="coming-soon-text">Coming Soon..</p>
          </div> */}
          <img className="Productpagemain-image" src={PRoduct1} alt="Villa" />
          <div className="Productpagemain-text-container">
            <h2 className="Productpagemain-title">Cosmetics </h2>
            <p className="Productpagemain-description">
              An opportunity regarding your farmland or agricultural land that
              may currently be lying idle. With the guidance of Royal Indian
              Sandalwood Private Limited.
            </p>
            <button className="know-more-btn" disabled>
              Coming Soon
            </button>
          </div>
        </div>
        <div className="Productpagemain-container" style={{marginBottom: "-100px"}}>
          {/* <div className="coming-soon-overlay">
            <p className="coming-soon-text">Coming Soon..</p>
          </div> */}
          <img className="Productpagemain-image" src={PRoduct1} alt="Villa" />
          <div className="Productpagemain-text-container">
            <h2 className="Productpagemain-title">Tissue culture</h2>
            <p className="Productpagemain-description">
              An opportunity regarding your farmland or agricultural land that
              may currently be lying idle. With the guidance of Royal Indian
              Sandalwood Private Limited.
            </p>
            <button className="know-more-btn" disabled>
              Coming Soon
            </button>
          </div>
        </div>
      </div>
      <Footer showBothDivs={false} />
    </Layout>
  );
};

export default Productpagemain;
