import React from "react";
// import "./Assessment.css";
import "./BookFlip.css";
import assessment_1 from "../../Assets/assessment_1.jpg";
import assessment_2 from "../../Assets/assessment_2.jpg";
import assessment_3 from "../../Assets/assessment_3.jpg";
import assessment_4 from "../../Assets/assessment_4.jpg";
import logo from "../../Assets/logo.png";

export const Assessment = () => {
  return (
    <div className="book">
      <div className="left-asses-imges">
        <div className="row">
          <div className="column">
            <img
              src={assessment_1}
              alt="Density"
              style={{ width: "100%", height: "248px" }}
            />
          </div>
          <div className="column">
            <img
              src={assessment_2}
              alt="Texture"
              style={{ width: "100%", height: "248px" }}
            />
          </div>
        </div>
        <div className="row">
          <div className="column">
            <img
              src={assessment_3}
              alt="Size and shape"
              style={{ width: "100%", height: "248px" }}
            />
          </div>
          <div className="column">
            <img
              src={assessment_4}
              alt="Heartwood"
              style={{ width: "100%", height: "248px" }}
            />
          </div>
        </div>
      </div>
      <div className="right-asses-info">
        <div>
          <h6
            style={{
              fontSize: "44px",
              fontWeight: "100",
            }}
          >
            SANDALWOOD HEARTWOOD ASSESSMENT
          </h6>
        </div>
        <div>
          <p>
            Sandalwood heartwood assessment is often performed by our experts in
            the field. It requires a combination of visual inspection, olfactory
            evaluation, and various technical tests. Sandalwood heartwood
            assessment involves evaluating various characteristics and
            properties of the wood, including:
          </p>
        </div>
        <div className="text-asses-p">
          <p>
            <b>1. Color:</b> The color of sandalwood heartwood is one of its
            most distinctive features.
          </p>

          <p>
            <b>2. Odor:</b> High-quality heartwood has a strong, sweet, and
            enduring scent, which is one of the key factors contributing to its
            value.
          </p>

          <p>
            <b>3. Density:</b> The density of the heartwood is a critical factor
            in assessing its quality: Denser wood tends to be more valuable, as
            it is associat ed with better durability and resistance to decay.
          </p>

          <p>
            <b>4. Oll content:</b> Sandalwood heartwood contains aromatic oils
            that contribute to its unique fragrance. The oil content is a key
            determinant of quality, as higher oil concentrations are generally
            preferred.
          </p>

          <p>
            <b>5. Texture:</b> Fine and even grain texture is desirable in
            sandalwood heartwood, as it enhances its visual appeal and
            workability for carving and other applications.
          </p>

          <p>
            <b>6. Heartwood-to-sapwood ratio:</b> Heartwood is the most valuable
            part of the sandalwood tree, so a higher proportion of heartwood in
            a harvested log is considered more desirable.
          </p>

          <p>
            <b>7. Size and shape:</b> The size and shape of sandalwood logs abo
            play a role in their assessment. Larger and straighter logs are
            generally more valuable for commercial purposes.
          </p>
        </div>
        {/* <div className="info-asses-logo">
          <img src={logo} alt="logo" style={{marginTop: "10px"}} />
        </div> */}
      </div>
    </div>
  );
};
export default Assessment;
