import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import "../Styles/TermsAndConditions.css";
import Layout from "../Components/Layout/Layout";
import Footer from "../Components/Layout/Footer";

const TermsAndConditions = () => {
  const location = useLocation();
  const [cartItems, setCartItems] = useState(location.state?.cartItems || []);

  return (
    <Layout setCartItems={setCartItems} cartItems={cartItems} isNavbar={true}>
      <div className="terms-and-conditions">
        <h1>Terms and Conditions</h1>
        <p>Effective Date: 26.08.2024</p>
        <p>
          Welcome to “ROYAL INDIAN SANDALWOOD PRIVATE LIMITED” ("we," "our," "us"). Please read these Terms and Conditions ("Terms") carefully before using our website www.royalindiansandalwood.com ("Website") and purchasing our products.
        </p>

        <h2>1. Acceptance of Terms</h2>
        <p>
          By accessing or using our Website and purchasing our products, you agree to be bound by these Terms and all applicable laws and regulations. If you do not agree with any part of these Terms, you must not use our Website or purchase our products.
        </p>

        <h2>2. Changes to Terms</h2>
        <p>
          We reserve the right to modify or update these Terms at any time without prior notice. Changes will be effective immediately upon posting on our Website. Your continued use of the Website or purchase of products constitutes acceptance of the updated Terms.
        </p>

        <h2>3. Use of Website</h2>
        <ul>
          <li>
            <strong>Eligibility:</strong> You must be at least 18 years old to use our Website and make purchases. By using our Website, you represent and warrant that you meet this requirement.
          </li>
          <li>
            <strong>Account Creation:</strong> To purchase products, you may need to create an account. You agree to provide accurate, current, and complete information during the registration process and to update such information as necessary to keep it accurate, current, and complete.
          </li>
          <li>
            <strong>Account Security:</strong> You are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer. You agree to accept responsibility for all activities that occur under your account.
          </li>
        </ul>

        <h2>4. Product Information</h2>
        <ul>
          <li>
            <strong>Descriptions and Availability:</strong> We strive to provide accurate descriptions and images of our products. However, we do not warrant that product descriptions or other content on our Website is accurate, complete, reliable, current, or error-free. Product availability is subject to change without notice.
          </li>
          <li>
            <strong>Natural Products:</strong> Our products are natural and may vary in appearance and characteristics. We make no warranties regarding the specific characteristics or benefits of the products.
          </li>
        </ul>

        <h2>5. Orders and Payment</h2>
        <ul>
          <li>
            <strong>Order Processing:</strong> All orders are subject to acceptance and availability. We reserve the right to refuse or cancel any order at our discretion.
          </li>
          <li>
            <strong>Payment:</strong> Payment for products must be made through our accepted payment methods. You agree to provide accurate payment information and authorize us to charge the total amount of your order, including applicable taxes and shipping fees.
          </li>
          <li>
            <strong>Pricing:</strong> Prices are subject to change without notice. We are not responsible for any errors in pricing and reserve the right to correct such errors.
          </li>
        </ul>

        <h2>6. Shipping and Delivery</h2>
        <ul>
          <li>
            <strong>Shipping:</strong> We will ship products to the address provided by you during the checkout process. Shipping costs and delivery times may vary based on your location and the shipping method selected.
          </li>
          <li>
            <strong>Risk of Loss:</strong> Risk of loss and title to the products pass to you upon delivery. We are not liable for any damage or loss that occurs during shipping.
          </li>
        </ul>

        <h2>7. Returns and Refunds</h2>
        <ul>
          <li>
            <strong>Return Policy:</strong> Our return policy is outlined on our Website. Products may be returned in accordance with our return policy. Please review the return policy before making a purchase.
          </li>
          <li>
            <strong>Refunds:</strong> Refunds will be processed in accordance with our return policy. We may issue refunds to the original payment method used for the purchase.
          </li>
        </ul>

        <h2>8. Intellectual Property</h2>
        <ul>
          <li>
            <strong>Ownership:</strong> All content on our Website, including text, graphics, logos, images, and software, is the property of “ROYAL INDIAN SANDALWOOD PRIVATE LIMITED” or its licensors and is protected by intellectual property laws.
          </li>
          <li>
            <strong>Use Restrictions:</strong> You may not use, reproduce, distribute, or create derivative works from any content on our Website without our prior written consent.
          </li>
        </ul>

        <h2>9. User Conduct</h2>
        <ul>
          <li>
            <strong>Prohibited Activities:</strong> You agree not to use our Website for any unlawful purpose or in any way that could damage, disable, overburden, or impair the Website. Prohibited activities include, but are not limited to, transmitting viruses, engaging in fraudulent activities, or attempting to gain unauthorized access to our systems.
          </li>
        </ul>

        <h2>10. Disclaimers and Limitation of Liability</h2>
        <ul>
          <li>
            <strong>Disclaimers:</strong> Our Website and products are provided on an "as is" and "as available" basis. We disclaim all warranties, express or implied, including but not limited to implied warranties of merchantability and fitness for a particular purpose.
          </li>
          <li>
            <strong>Limitation of Liability:</strong> To the fullest extent permitted by law, “ROYAL INDIAN SANDALWOOD PRIVATE LIMITED” shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising from or related to your use of the Website or purchase of products.
          </li>
        </ul>

        <h2>11. Indemnification</h2>
        <p>
          You agree to indemnify and hold “ROYAL INDIAN SANDALWOOD PRIVATE LIMITED” harmless from any claims, liabilities, damages, losses, or expenses (including reasonable attorneys' fees) arising from your use of our Website, your violation of these Terms, or your infringement of any rights of a third party.
        </p>

        <h2>12. Governing Law</h2>
        <p>
          These Terms shall be governed by and construed in accordance with the laws of Bengaluru, INDIA, without regard to its conflict of law principles. Any disputes arising under or in connection with these Terms shall be subject to the exclusive jurisdiction of the courts located in Bengaluru / INDIA.
        </p>

        <h2>13. Contact Information</h2>
        <p>
          If you have any questions or concerns about these Terms, please contact us at:
        </p>
        <p>
          “ROYAL INDIAN SANDALWOOD PRIVATE LIMITED”
          <br />
          Ward 9, Block 65 2, Near Jindhal Srikanthapura, Bengaluru 560073,
          Karnataka, India.
          <br />
          Email:{" "}
          <a href="mailto:info@royalindiansandalwood.com">
            info@royalindiansandalwood.com
          </a>
          <br />
          Contact number: +91 9004015163
        </p>
      </div>
      <Footer showBothDivs={false} />
    </Layout>
  );
};

export default TermsAndConditions;
