export const otpRequestType = {
    register: "register",
    login: "login",
    forgot: "forgot",
    resend: "resend"
}

export const otpVerifyType = {
    sendOTP: "sendOTP",
    verifyOTP: "verifyOTP"
}