import React, { useState } from "react";
import "../.././Styles/PlantationServices.css";
import bubble_1 from "../../Assets/bubble1.png";
import bubble_3 from "../../Assets/bubble3.png";
import perlimg from "../../Assets/pearl.png";
import r_leaf from "../../Assets/r_leaf.png";
import servicespage1 from "../.././Assets/servicespage1.jpeg";
import Farmland from "../.././Assets/Service_plantation2.png";
import Villa from "../.././Assets/Service_plantation3.png";
import Chandan from "../.././Assets/Service_plantation1.png";
import Layout from "../.././Components/Layout/Layout";
import { useNavigate } from "react-router-dom";



const PlantationServices = () => {
  const [cartItems, setCartItems] = useState([]);
  const navigate = useNavigate();

  const handleBannerClick = () => {
    window.scrollTo(0, 0);
    navigate(`/Gifting`);
  };

  const handleClick1 = () => {
    window.scrollTo(0, 0);
    navigate(`/FarmlandPlantation`);
  };
  const handleClick2 = () => {
    window.scrollTo(0, 0);
    navigate(`/Villa_Bunglow`);
  };
  const handleClick3 = () => {
    window.scrollTo(0, 0);
    navigate(`/Villa_Bunglow`);
  };

  return (
    <Layout
      setCartItems={setCartItems}
      cartItems={cartItems}
      isFooter={true}
      isNavbar={true}
    >
      <div className="service-Plantation-bg row position-relative">
        <span>
          <img className="perl-imgs d-md" src={perlimg} alt="" />
        </span>
        <span>
          <img className="r-leaff d-md" src={r_leaf} alt="" />
        </span>
        {/* <img className="bubblee" id="bubbleonee" src={bubble_3} alt="" /> */}
        <img className="bubblee" id="bubblefourr" src={bubble_1} alt="" />
      </div>
      <div className="services-Plantation-container">
        <div className="services-Plantation-content">
          <img className="images-Plantation" src={servicespage1} alt="" />
          <p className="services-description-Plantation">Plantation Model</p>
        </div>
      </div>
      <div className="farmland-section">
        {/* <div className="farmlandd-container">
          <img className="farmlandd-image" src={servicespage1} alt="Farmland" />
          <div className="farmlandd-text-container">
            <h2 className="farmlandd-title">Plantation Services</h2>
            <p className="farmlandd-description">
              An opportunity regarding your farmland or agricultural land that
              may currently be lying idle. With the guidance of Royal Indian
              Sandalwood Private Limited.
            </p>
            <button className="know-more-button">Know More</button>
          </div>
        </div> */}
        <div className="farmlandd-container">
          <img className="farmlandd-image" src={Farmland} alt="Farmland" />
          <div className="farmlandd-text-container">
            <h2 className="farmlandd-title">Farmland Sandalwood Plantation</h2>
            <p className="farmlandd-description">
              An opportunity regarding your farmland or agricultural land that
              may currently be lying idle. With the guidance of Royal Indian
              Sandalwood Private Limited.
            </p>
            <button className="know-more-button" onClick={handleClick1}>Know More</button>
          </div>
        </div>
        <div className="farmlandd-container farmland2">
          <img className="farmlandd-image" src={Villa} alt="Villa" />
          <div className="farmlandd-text-container">
            <h2 className="farmlandd-title">
              Villa/Bunglow sandalwood plantation
            </h2>
            <p className="farmlandd-description">
              An opportunity regarding your farmland or agricultural land that
              may currently be lying idle. With the guidance of Royal Indian
              Sandalwood Private Limited.
            </p>
            <button className="know-more-button" onClick={handleClick2}>Know More</button>
          </div>
        </div>
        <div className="farmlandd-container" style={{ marginBottom: "-190px" }}>
          <img className="farmlandd-image" src={Chandan} alt="Villa" />
          <div className="farmlandd-text-container">
            <h2 className="farmlandd-title">Har Ghar Chandan</h2>
            <p className="farmlandd-description">
              An opportunity regarding your farmland or agricultural land that
              may currently be lying idle. With the guidance of Royal Indian
              Sandalwood Private Limited.
            </p>
            <button className="know-more-button" onClick={handleBannerClick}>Know More</button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PlantationServices;
