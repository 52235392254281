import React, { useState, useEffect } from "react";
import axios from "axios";
import "./css/Address.css";
import toast from "react-hot-toast";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit"; 
import UserMenu from "../../Components/Layout/UserMenu";
import Layout from "../../Components/Layout/Layout";
import AddAddressPopup from "../AddAddressPopup";

const Address = () => {
    const [cartItems, setCartItems] = useState([]);
  const [addressData, setAddressData] = useState([]);
  const [addressLoading, setAddressLoading] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        const response = await axios.get(
          "https://rispl.backendapihub.com/address/getall",
          {
            headers: {
              "access-token": "1",
            },
          }
        );
        if (response.data && Array.isArray(response.data.data)) {
          setAddressData(response.data.data);
        } else {
          console.error("Unexpected data format:", response.data);
          setAddressData([]);
        }
        setAddressLoading(false);
      } catch (error) {
        console.error("Error fetching addresses:", error);
        setAddressLoading(false);
      }
    };

    fetchAddresses();
  }, []);

  const handleDeleteAddress = async (addressId, index) => {
    try {
      await axios.delete(
        `https://rispl.backendapihub.com/address/delete/${addressId}`,
        {
          headers: {
            "access-token": "1",
          },
        }
      );
      const updatedAddressData = addressData.filter((_, i) => i !== index);
      setAddressData(updatedAddressData);
      toast.success("Address deleted successfully!"); 
  } catch (error) {
      console.error("Error deleting address:", error);
      toast.error("Failed to delete address.");
  }
};

  const handleEditAddress = (addressId) => {
    // Implement the edit functionality here
    console.log("Edit address with ID:", addressId);
  };

  const handleAddAddress = (newAddress) => {
    setAddressData((prevAddressData) => [...prevAddressData, newAddress]);
  };

  return (
    <Layout setCartItems={setCartItems} cartItems={cartItems} isFooter={true} isNavbar={true}>
      <div className="dash-top-section"></div>
      <div className="dash-container">
        <div className="d-flex">
          <UserMenu />
          <div className="Address-container">
            <div className="Address-header">
              <div className="Address-left">
                <h2>Address</h2>
              </div>
              <button
                className="Add-address-button Address-right"
                onClick={() => setIsPopupOpen(true)}
              >
                + Add New Address
              </button>
            </div>
            <div className="Shipping-address">
              <div className="Shipping-address-content">
                {addressLoading ? (
                  <div>Loading...</div>
                ) : addressData.length === 0 ? (
                  <p>No shipping addresses available</p>
                ) : (
                  addressData.map((address, index) => (
                    <div key={address.id} className="Address-card">
                      <div className="Address-details">
                        <p>
                          {address.address1}, {address.address2}
                        </p>
                        <p>
                          {address.city} - {address.pincode}
                        </p>
                        <p>{address.state}</p>
                      </div>
                      <div className="Address-actions">
                        {/* <button
                          className="Edit-btn"
                          aria-label="Edit Address"
                          onClick={() => handleEditAddress(address.id)}
                        >
                          <EditIcon /> Edit
                        </button> */}
                        <button
                          className="Delete-btn"
                          aria-label="Delete Address"
                          onClick={() => handleDeleteAddress(address.id, index)}
                        >
                          <DeleteIcon /> Delete
                        </button>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <AddAddressPopup
        open={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        onAddAddress={handleAddAddress}
      />
    </Layout>
  );
};

export default Address;
