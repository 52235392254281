import React, { useState } from "react";
// import Layout from "./../../Components/Layout/Layout";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
// import "../../Styles/AuthStyles.css";
import "./ForgotPassword.css"
const ForgotPasssword = () => {
  const [email, setEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [answer, setAnswer] = useState("");

  const navigate = useNavigate();

  // form function
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post("/api/v1/auth/forgot-password", {
        email,
        newPassword,
        answer,
      });
      if (res && res.data.success) {
        toast.success(res.data && res.data.message);

        navigate("/login");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };
  return (
    // <div title="Login/Register - Sandalwood">
    //   <div className="d-flex justify-content-center">
    //     <div className="login-form-container">
    //       <form id="form" onSubmit={isRegistering ? null : handleLoginSubmit}>
    //         <div className="d-flex align-items-center justify-content-center">
    //           <h4 className="lg-title my-2">{isRegistering ? "REGISTER" : "LOGIN"}</h4>
    //         </div>
    //         <div className="fill-back mb-3 d-flex align-items-center p-2">
    //           <img className="m-1" src={Emailicon} alt="" />
    //           <input
    //             type="email"
    //             autoFocus
    //             value={email}
    //             onChange={(e) => setEmail(e.target.value)}
    //             className="flex-grow-1 form-control p-0"
    //             id="exampleInputEmail1"
    //             placeholder="Enter Your Email "
    //             required
    //           />
    //         </div>
    //         <div className="fill-back mb-3 d-flex align-items-center p-2">
    //           <img className="m-1" src={Passwordicon} alt="" />
    //           <input
    //             type="password"
    //             value={password}
    //             onChange={(e) => setPassword(e.target.value)}
    //             className="flex-grow-1 form-control p-0"
    //             id="exampleInputPassword1"
    //             placeholder="Enter Your Password"
    //             required
    //           />
    //         </div>
    //         {!isRegistering && (
    //           <div className="">
    //             <div
    //               type="text"
    //               className="fg-pass"
    //               onClick={() => {
    //                 navigate("forgot-password");
    //               }}
    //             >
    //               Forgot Password ?
    //             </div>

    //             <button type="submit"
    //               className="lg-btn btn-primary w-100">
    //               LOGIN
    //             </button>
    //           </div>
    //         )}
    //         {isRegistering && <Register />}
    //         <p className="loginsignup-login d-flex justify-content-center">
    //           {isRegistering ? "Already have an account?" : "Create your . "}
    //           <span className="lta" onClick={toggleRegister}>{isRegistering ? " Login here" : "account with us"}</span>
    //         </p>
    //       </form>
    //     </div>
    //   </div>
    // </div>




    <div title={"Forgot Password - Sandalwood"}>
     <div className="d-flex justify-content-center">
      <div className="fg-form-container">
        <form onSubmit={handleSubmit}>
          <h4 className="fg-title">RESET PASSWORD</h4>

          <div className=" fg-fill-back mb-3">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="form-control"
              id="exampleInputEmail1"
              placeholder="Enter Your Email "
              required
            />
          </div>
          <div className="fg-fill-back mb-3">
            <input
              type="text"
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
              className="form-control"
              id="exampleInputEmail1"
              placeholder="Enter Your favorite Sport Name "
              required
            />
          </div>
          <div className=" fg-fill-back mb-3">
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="form-control"
              id="exampleInputPassword1"
              placeholder="Enter Your Password"
              required
            />
          </div>

          <button type="submit" className="fg-btn btn-primary w-100 ">
            RESET
          </button>
        </form>
      </div>
    </div>
    </div>
  );
};

export default ForgotPasssword;