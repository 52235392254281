import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "../Styles/Gifting.css";
// import "../Styles/Testvideo.css";
import video1 from "../Assets/video5.mp4";
import defaultImage from "../Assets/consultancy_3.jpg";
import video2 from "../Assets/video2.mp4";
import video3 from "../Assets/video3.mp4";
import video4 from "../Assets/video1.mp4";
import videodef from "../Assets/defvid.mp4";
import ben1 from "../Assets/benefit-icon1.png";
import ben2 from "../Assets/benefit-icon2.png";
import ben3 from "../Assets/benefit-icon3.png";
import ben4 from "../Assets/benefit-icon4.png";
import ben5 from "../Assets/benefit-icon5.png";
import ben6 from "../Assets/benefit-icon6.png";
import Vtr1 from "../Assets/Vtr1.png";
import Vtr2 from "../Assets/Vtr2.png";
import Vtr3 from "../Assets/Vtr3.png";
import Vtr4 from "../Assets/Vtr4.png";
import hrline from "../Assets/hr_line.png";
import vrline from "../Assets/vr_line.png";
import leaf_vtr from "../Assets/gift-vector-leaf.png";
import sdot from "../Assets/smalldot.png";
import bdot from "../Assets/bigdot.png";
import flower1 from "../Assets/gift-faq-one.png";
import flower2 from "../Assets/gift-faq-two.png";
import leafvtr2 from "../Assets/gift-vector-leaf2.png";
import Navbar from "../Components/Layout/Navbar";
import Footer from "../Components/Layout/Footer";
import Layout from "../Components/Layout/Layout";

const Gifting = () => {
  const [progress, setProgress] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const videodefRef = useRef(null);
  // const video1Ref = useRef(null);
  const video2Ref = useRef(null);
  const video3Ref = useRef(null);
  const video4Ref = useRef(null);
  const scrubberRef = useRef(null);

  const handleVideoHover = (videoRef) => {
    // Pause the default video, reset z-index, and play the hovered video
    setIsHovered(true);
    setIsVideoPlaying(true);
    if (videodefRef.current) {
      videodefRef.current.pause();
      videodefRef.current.style.zIndex = 2;
    }
    if (videoRef.current) {
      videoRef.current.play();
      videoRef.current.style.zIndex = 3;
    }
  };

  const handleVideoHoverOut = () => {
    // Pause all videos
    setIsHovered(false);
    setIsVideoPlaying(false);

    // if (video1Ref.current) video1Ref.current.pause();
    if (video2Ref.current) video2Ref.current.pause();
    if (video3Ref.current) video3Ref.current.pause();
    if (video4Ref.current) video4Ref.current.pause();

    // Reset z-index for all videos
    // if (video1Ref.current) video1Ref.current.style.zIndex = 2;
    if (video2Ref.current) video2Ref.current.style.zIndex = 2;
    if (video3Ref.current) video3Ref.current.style.zIndex = 2;
    if (video4Ref.current) video4Ref.current.style.zIndex = 2;

    // Play the default video and bring it to the front
    if (videodefRef.current) {
      videodefRef.current.play();
      videodefRef.current.style.zIndex = 3;
    }
  };

  // Update the progress as the video plays
  const handleTimeUpdate = (videoRef) => {
    if (videoRef.current) {
      const progressPercent =
        (videoRef.current.currentTime / videoRef.current.duration) * 100;
      setProgress(progressPercent);
    }
  };

  const [cartItems, setCartItems] = useState([]);
  const [harGharText, setHarGharText] = useState("");
  const [chandanText, setChandanText] = useState("");
  const [languageIndex, setLanguageIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [typingSpeed, setTypingSpeed] = useState(150);
  const toRotate = [
    ["har ghar chandan"],
    ["हर घर चंदन"],
    ["હર ઘર ચંદન"],
    ["ஹர் கர் சந்தன்"],
  ];
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, typingSpeed);

    return () => {
      clearInterval(ticker);
    };
  }, [harGharText, chandanText, typingSpeed]);

  const tick = () => {
    let languageLength = toRotate[languageIndex].length;
    let fullHarGharText = toRotate[languageIndex][0] + " ";
    let fullChandanText = toRotate[languageIndex][1];
    let updatedHarGharText = isDeleting
      ? fullHarGharText.substring(0, harGharText.length - 1)
      : fullHarGharText.substring(0, harGharText.length + 1);

    setHarGharText(updatedHarGharText);

    if (!isDeleting && updatedHarGharText === fullHarGharText) {
      setIsDeleting(true);
      setTypingSpeed(period);
    } else if (isDeleting && updatedHarGharText === "") {
      setIsDeleting(false);
      setLanguageIndex((languageIndex + 1) % toRotate.length);
      setTypingSpeed(150);
    } else {
      setTypingSpeed(150);
    }
  };

  const addToCart = () => {
    // if (!selectedSize) {
    //   toast.error("Please select a size.");
    //   return;
    // }

    const data = {
      l1_id: 10, 
      quantity: 1,
      size_id: 0,
    };
    const accessToken = "1"; 
    axios
      .post("https://rispl.backendapihub.com/cart/add", data, {
        headers: {
          "access-token": accessToken,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Product added to cart successfully!");
          // fetchCartItems(); 
        } else {
          toast.error("Failed to add item to cart.");
        }
      })
      .catch((error) => {
        toast.error("Error adding item to cart.");
      });
  };

  return (
    <>
      {/* <Navbar showNormalNav={false} /> */}
      <Layout setCartItems={setCartItems} cartItems={cartItems}  isNavbar={true} textColor="white" /> 
      <div className="position-relative">
        <div className="Containeranime">
          <div className="box banner position-relative">
            <h6 className="gifting-banner-title">
              {harGharText} {chandanText}
            </h6>
           
          </div>
        </div>

        {/* Benefits section */}
        <div className="benefits position-relative">
        <img className="leaf-vtr" src={leaf_vtr} alt="" />
          <div className="inner-benefits">
            <h1 className="ps-5 p-sm-4">Why Har Ghar Chandan?</h1>

            <div className="benefits-content d-flex flex-wrap justify-content-evenly gap-1">
              {/* 1st */}
              <div className="outer-content ps-5 p-sm-0 py-sm-3">
                <div className="img">
                  <img src={ben1} alt="" />
                </div>

                <div className="content pt-4">
                  <h2>Personal Pride</h2>
                  <p>
                    It is a matter of personal pride to own and
                    <br />
                    grow a sandalwood plant at home
                  </p>
                  <img id="hrl" src={hrline} alt="" />
                </div>
              </div>

              {/* 2nd */}
              <div className="outer-content ps-5 p-sm-0 py-sm-3">
                <div className="img">
                  <img src={ben2} alt="" />
                </div>

                <div className="content pt-4">
                  <h2>Good Luck And Wealth</h2>
                  <p>It's a plant of good luck and wealth</p>
                  <img className="hrlineadj" id="hrl1" src={hrline} alt="" />
                </div>
              </div>

              {/* 3rd */}
              <div className="outer-content ps-5 p-sm-0 py-sm-3">
                <div className="img">
                  <img src={ben3} alt="" />
                </div>

                <div className="content pt-4">
                  <h2>Asset</h2>
                  <p>It's an asset for yourself</p>
                  <img className="hrlineadj" id="hrl2" src={hrline} alt="" />
                </div>
              </div>

              {/* 4th */}
              <div className="outer-content ps-5 p-sm-0 py-sm-3">
                <div className="img">
                  <img src={ben4} alt="" />
                </div>

                <div className="content pt-4">
                  <h2>Positive Energy</h2>
                  <p>
                    It creates an aura and spreads a lot of
                    <br /> positive energy.
                  </p>
                </div>
              </div>

              {/* 5th */}
              <div className="outer-content ps-5 p-sm-0 py-sm-3">
                <div className="img">
                  <img src={ben5} alt="" />
                </div>

                <div className="content pt-4">
                  <h2>Low Maintenance</h2>
                  <p>It's a low maintenance less water plant.</p>
                </div>
              </div>

              {/* 6th */}
              <div className="outer-content ps-5 p-sm-0 pt-sm-3">
                <div className="img">
                  <img src={ben6} alt="" />
                </div>

                <div className="content pt-4">
                  <h2>Rebuilding Our Country</h2>
                  <p>
                    It's a small contribution by every individual <br />
                    in rebuilding our countries lost legacy
                  </p>
                </div>
              </div>
              <img className="vrlineadj" id="vr1" src={vrline} alt="" />
              <img className="vrlineadj" id="vr2" src={vrline} alt="" />
            </div>
          </div>
          <img className="gift-leaf-vtr2" src={leafvtr2} alt="" />
        </div>
      </div>

      <div className="gftParent">
        <div className="gftmain ">
          <div className="Experience-section text-center">
            <h1>Experience the product</h1>
            <p>Click on each service to explore more</p>

            <div className="circular-video-container mt-5 ">
              <video
                ref={videodefRef}
                className="circular-video"
                id="viddef"
                src={videodef}
                loop
                onTimeUpdate={() => handleTimeUpdate(videodefRef)}
              />

              {/* Scrubber for the default video */}
              <div
                ref={scrubberRef}
                className="scrubber"
                style={{
                  background: `conic-gradient(white ${progress}%, transparent 0)`,
                }}
              ></div>

              {/* Videos and headers for other videos */}
              {/* Repeat this block for each video */}
              {/* <video
                ref={video1Ref}
                className="circular-video"
                id="vid1"
                src={video1}
                loop
                onTimeUpdate={() => handleTimeUpdate(video1Ref)}
              />
              <h3
                className="vtr-head vtr1-title position-absolute"
                onMouseEnter={() => handleVideoHover(video1Ref)}
                onMouseLeave={handleVideoHoverOut}
              >
                Boxing <br />
                Method
              </h3> */}

              <video
                ref={video2Ref}
                className="circular-video"
                id="vid2"
                src={video2}
                loop
                onTimeUpdate={() => handleTimeUpdate(video2Ref)}
              />
              <h3
                className="vtr-head vtr2-title position-absolute"
                onMouseEnter={() => handleVideoHover(video2Ref)}
                onMouseLeave={handleVideoHoverOut}
              >
                Unboxing <br />
                method
              </h3>

              <video
                ref={video3Ref}
                className="circular-video"
                id="vid3"
                src={video3}
                loop
                onTimeUpdate={() => handleTimeUpdate(video3Ref)}
              />
              <h3
                className="vtr-head vtr3-title position-absolute"
                onMouseEnter={() => handleVideoHover(video3Ref)}
                onMouseLeave={handleVideoHoverOut}
              >
                Do's and <br />
                Dont's
              </h3>

              <video
                ref={video4Ref}
                className="circular-video"
                id="vid4"
                src={video4}
                loop
                onTimeUpdate={() => handleTimeUpdate(video4Ref)}
              />
              <h3
                className="vtr-head vtr4-title position-absolute"
                onMouseEnter={() => handleVideoHover(video4Ref)}
                onMouseLeave={handleVideoHoverOut}
              >
                Gift A Plant
              </h3>
            </div>
            <div>
              <div className="Vtr1">
                <img src={Vtr1} alt="" />
              </div>
              {/* <div className="Vtr2">
                <img src={Vtr2} alt="" />
              </div> */}
              <div className="Vtr3">
                <img src={Vtr3} alt="" />
              </div>
              <div className="Vtr4">
                <img src={Vtr4} alt="" />
              </div>
              <div className="smalldot">
                <img id="sdott1" src={sdot} alt="" />
                <img id="sdott2" src={sdot} alt="" />
                <img id="sdott3" src={sdot} alt="" />
                <img id="sdott4" src={sdot} alt="" />
              </div>
              <div className="bigdot">
                <img id="bdott1" src={bdot} alt="" />
                <img id="bdott2" src={bdot} alt="" />
                <img id="bdott3" src={bdot} alt="" />
                <img id="bdott4" src={bdot} alt="" />
              </div>
            </div>
            <div className="gift-btn-div text-center d-flex justify-content-center align-items-center">
              <button className="buy-now-button" onClick={addToCart}>
                BUY NOW
              </button>
            </div>
            <div className="outercircle">
               {/* Default image element */}
               {!isHovered && !isVideoPlaying && (
                <img
                  src={defaultImage}
                  alt="Default"
                  className="default-image"
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {/* FAQ */}

      <div className="FooterContainer">
        <div className="accordian-background mt-5">
          <h1 className="text-center">Frequently Asked Questions</h1>
          <div className="Accordian position-relative">
            <div class="accordion accor-main" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    1. Can you tell us the forte of your company?
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  class="accordion-collapse collapse show"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      The strength of our company lies upon its core values in
                      terms of ethics, integrity, trust and loyalty towards our
                      customer.
                    </p>
                  </div>
                </div>
              </div>
              <hr className="m-0 faqdivider" />

              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    2. Where is your company located?
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      The strength of our company lies upon its core values in
                      terms of ethics, integrity, trust and loyalty towards our
                      customer.
                    </p>
                  </div>
                </div>
              </div>
              <hr className="m-0 faqdivider" />

              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    3. Which all part of the country does your company provide
                    its services?
                  </button>
                </h2>
                <div
                  id="collapseThree"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      The strength of our company lies upon its core values in
                      terms of ethics, integrity, trust and loyalty towards our
                      customer.
                    </p>
                  </div>
                </div>
              </div>
              <hr className="m-0 faqdivider" />

              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFour"
                    aria-expanded="false"
                    aria-controls="collapseFour"
                  >
                    4. Which sandalwood does your company deal with?
                  </button>
                </h2>
                <div
                  id="collapseFour"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      The strength of our company lies upon its core values in
                      terms of ethics, integrity, trust and loyalty towards our
                      customer.
                    </p>
                  </div>
                </div>
              </div>
              <hr className="m-0 faqdivider" />

              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseFive"
                    aria-expanded="false"
                    aria-controls="collapseFive"
                  >
                    5. What are the different names of sandalwood?
                  </button>
                </h2>
                <div
                  id="collapseFive"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      The strength of our company lies upon its core values in
                      terms of ethics, integrity, trust and loyalty towards our
                      customer.
                    </p>
                  </div>
                </div>
              </div>
              <hr className="m-0 faqdivider" />

              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSix"
                    aria-expanded="false"
                    aria-controls="collapseSix"
                  >
                    6. Is red sandalwood and yellow sandalwood same?
                  </button>
                </h2>
                <div
                  id="collapseSix"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      The strength of our company lies upon its core values in
                      terms of ethics, integrity, trust and loyalty towards our
                      customer.
                    </p>
                  </div>
                </div>
              </div>
              <hr className="m-0 faqdivider" />

              <div class="accordion-item">
                <h2 class="accordion-header">
                  <button
                    class="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseSeven"
                    aria-expanded="false"
                    aria-controls="collapseSeven"
                  >
                    7. What are the main differences between red and yellow
                    sandalwood?
                  </button>
                </h2>
                <div
                  id="collapseSeven"
                  class="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                >
                  <div class="accordion-body">
                    <p>
                      The strength of our company lies upon its core values in
                      terms of ethics, integrity, trust and loyalty towards our
                      customer.
                    </p>
                  </div>
                </div>
              </div>
              <hr className="m-0 faqdivider" />
            </div>
            <img className="faq-flower-1" src={flower1} alt="flower1" />
            <img className="faq-flower-2" src={flower2} alt="flower2" />
          </div>
        </div>
      </div>
      {/* <Footer/> */}
      <Footer showBothDivs={false} />
    </>
  );
};

export default Gifting;
