import React from "react";
// import "./WeHelpU.css";
import "./BookFlip.css";
import hold_finger_img from "../../Assets/hold-finger-img.jpg";
import logo from "../../Assets/logo.png";

export const WeHelpU = () => {
  return (
    <div className="book">
      <div className="left-help-img">
        <img
          src={hold_finger_img}
          alt="hold_finger_img"
          style={{ width: "100%", height: "500px" }}
        />
      </div>
      <div className="right-help-text">
        <div>
          <h6 style={{ fontSize: "80px", fontWeight: "100" }}>
            HOW CAN <br /> WE HELP YOU?
          </h6>
        </div>
        <div>
          <p style={{ width: "100%" }}>
            We offer assistance to both individual farmers and corporate
            entities through our comprehensive services dedicated to Indian
            Sandal wood (Chandan) cultivation. The services we offer encompass
            the following:
          </p>
        </div>
        <div className="help-p">
          <p>
            <b>Sandalwood plantation</b>
          </p>
        </div>
        <div className="help-p">
          <p>
            <b>Sandalwood maintenance</b>
          </p>
        </div>
        <div className="help-p">
          <p>
            <b>Sandalwood Heartwood assessment</b>
          </p>
        </div>
        <div className="help-p">
          <p>
            <b>Sandalwood training and skill development</b>
          </p>
        </div>
        <div className="help-p">
          <p>
            <b>Sandalwood customer support</b>
          </p>
        </div>
        <div className="help-p">
          <p>
            <b>Sandalwood CSR (Corporate Social Responsibility) projects</b>
          </p>
        </div>
        <div className="help-p">
          <p>
            <b>Sandalwood consultancy</b>
          </p>
        </div>
        {/* <div className="info-logo">
          <img src={logo} alt="logo" />
        </div> */}
      </div>
    </div>
  );
};
export default WeHelpU;
