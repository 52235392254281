import React, { useState } from "react";
import "../../Styles/Training.css";
import villa1 from "../.././Assets/villa1.png";
import training_img1 from "../.././Assets/training_img1.png";
import Layout from "../.././Components/Layout/Layout";
import Footer from "../.././Components/Layout/Footer";

const Training = () => {
  const [cartItems, setCartItems] = useState([]);

  return (
    <Layout setCartItems={setCartItems} cartItems={cartItems} isNavbar={true} textColor="white">
      <section className="Training-section">
        <div className="Training-text-overlay">
          <h1>
            <span className="Training-textt-1">Training</span>
          </h1>
        </div>
      </section>
      <section className="Training-details-section">
        <div className="Training-details-content">
          <div className="Training-details-text">
            <h2>Training</h2>
            <p>
              The sandalwood training program aims to educate participants about
              the cultivation, harvesting, and processing of sandalwood trees.
              It covers topics such as soil preparation, planting techniques,
              irrigation methods, pest and disease management, and the
              sustainable harvesting of sandalwood. Participants also learn
              about the economic aspects, market trends, and value-added
              products derived from sandalwood. Overall, the program equips
              individuals with the knowledge and skills needed to engage in
              sustainable sandalwood cultivation and management practices.
            </p>
          </div>
          <div className="Training-details-image">
            <img src={villa1} alt="villa Sandalwood Plantation" />
          </div>
        </div>
        <div className="Training-additional-content">
          <div className="Training-additional-content-layer">
            <h3 className="content-layer-text">upcoming training program</h3>
            <img src={training_img1} alt="training_img1" />
            <img src={training_img1} alt="training_img1" />
            <img src={training_img1} alt="training_img1" />
          </div>
        </div>
      </section>
      <Footer showBothDivs={false} />
    </Layout>
  );
};

export default Training;
