import React from 'react'
import Hero from './Hero'
import About from './About'
import Offers from './Offers'
import BestSellings from './BestSellings'
import Freebeauty from './FreeBeauty'
import Categories from './Categories'
import Handicrafts from './Handicrafts'
import Collection from './Collection'
import TissueBanner from './TissueBanner'
import Testimonial from './Testimonial'
import Footer from '../Layout/Footer'
import { FaLaptopHouse } from 'react-icons/fa'

const SubHome = () => {
  return (
    <div>
        <Hero/>
        <About showAboutMainpg={true} />
        <br/>
        <Offers/>
        {/* <BestSellings/> */}
        <Freebeauty/>
        <Categories/>
        <Handicrafts/>
        {/* <Collection/> */}
        <TissueBanner/>
        <Testimonial/>
        {/* <Footer showBothDivs={true} /> */}
    </div>
  )
}

export default SubHome