import React, { useState } from "react";
import Layout from "./../../Components/Layout/Layout";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import "../../Styles/AuthStyles.css";
import { registerUser, sendORVerifyOtp } from "../../service/AuthService";
import { otpRequestType, otpVerifyType } from "../../utils/Enums";

const Register = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");

  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // const [bill_address, setBillAddress] = useState("");
  // const [ship_address, setShipAddress] = useState("");
  // const [answer, setAnswer] = useState("");
  const navigate = useNavigate();

  const isFormValid = () => {
    return name && email && password && phone && otp;
  };

  // handle OTP send
  const handleSendOtp = async () => {
    try {
      const res = await sendORVerifyOtp(
        otpVerifyType.sendOTP,
        otpRequestType.register,
        email,
        phone,
        otp
      )
      if (res && res.data.success) {
        toast.success(res.data.message);
        setIsOtpSent(true);
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to send OTP");
    }
  };

  // form function
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = registerUser(
        name,
        phone,
        otp,
        email,
        password
      )

      // const res = await axios.post("/api/v1/auth/register", {
      //   name,
      //   email,
      //   password,
      //   phone,
      //   bill_address,
      //   ship_address,
      //   answer,
      // });
      if (res && res.data.success) {
        toast.success(res.data && res.data.message);
        navigate("/login");
      } else {
        toast.error(res.data.message);
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

  return (
    <Layout>
      <div title="Login/Register - Sandalwood">
        <div className="d-flex justify-content-center">
          <div className="login-form-container">
            <div className="form-container" style={{ minHeight: "90vh" }}>
              <form onSubmit={handleSubmit}>
                <h4 className="title">REGISTER FORM</h4>
                <div className="mb-3">
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder="Enter Your Name"
                    required
                    autoFocus
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder="Enter Your Email "
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="form-control"
                    id="exampleInputPassword1"
                    placeholder="Enter Your Password"
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder="Enter Your Phone"
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder="Enter Your OTP"
                    required
                  />
                </div>

                <button
                  type="button"
                  // className="btn btn-secondary"
                  onClick={handleSendOtp}
                  disabled={isOtpSent}
                >
                  {isOtpSent ? "OTP Sent" : "Send OTP"}
                </button>

                {/* <div className="mb-3">
                  <input
                    type="text"
                    value={bill_address}
                    onChange={(e) => setBillAddress(e.target.value)}
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder="Enter Your Billing Address"
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    value={ship_address}
                    onChange={(e) => setShipAddress(e.target.value)}
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder="Enter Your Shipping Address"
                    required
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    value={answer}
                    onChange={(e) => setAnswer(e.target.value)}
                    className="form-control"
                    id="exampleInputEmail1"
                    placeholder="What is Your Favorite sports"
                    required
                  />
                </div> */}

                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={!isFormValid() || isSubmitting}
                >
                  {isSubmitting ? "Registering..." : "REGISTER"}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Register;