import React from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation hook
import '../Styles/PaymentResult.css'; // Include your CSS file for styling

const PaymentSuccessPage = () => {
  const location = useLocation(); // Get location using the hook
  const { transactionId, amount } = location.state || {};

  return (
    <div className="containerr">
      <div className="printer-top"></div>

      <div className="paper-container">
        <div className="printer-bottom"></div>

        <div className="paperr">
          <div className="main-contents">
            <div className="success-icon">&#10004;</div>
            <div className="success-title">Payment Complete</div>
            <div className="success-description">
              Thank you for completing the payment! 
              {/* You will shortly receive an email of your payment. */}
            </div>
            <div className="order-details">
              <div className="order-number-label">Transaction ID</div>
              <div className="order-number">{transactionId}</div>
              <div className="order-number-label">Amount Paid</div>
              <div className="order-number">₹{amount}</div>
              <div className="complement">Thank You!</div>
            </div>
          </div>
          <div className="jagged-edge"></div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccessPage;
