import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import "../Styles/PrivacyPolicy.css";
import Layout from "../Components/Layout/Layout";
import Footer from "../Components/Layout/Footer";

const PrivacyPolicy = () => {
  const location = useLocation();
  const [cartItems, setCartItems] = useState(location.state?.cartItems || []);

  return (
    <Layout setCartItems={setCartItems} cartItems={cartItems} isNavbar={true}>
      <div className="privacy-policy">
        <h1>Privacy Policy</h1>
        <p>Effective Date: 26.08.2024</p>

        <h2>1. Introduction</h2>
        <p>
          Welcome to “ROYAL INDIAN SANDALWOOD PRIVATE LIMITED” ("we," "our," or
          "us"). We value your privacy and are committed to protecting your
          personal information. This Privacy Policy explains how we collect,
          use, disclose, and safeguard your information when you visit our
          website{" "}
          <a
            href="http://www.royalindiansandalwood.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.royalindiansandalwood.com
          </a>{" "}
          (the "Site") and make purchases of our sandalwood products.
        </p>

        <h2>2. Information We Collect</h2>
        <p>
          We collect various types of information to provide and improve our
          services, including:
        </p>
        <ul>
          <li>
            <strong>Personal Information:</strong> When you place an order,
            create an account, or contact us, we may collect personal
            information such as your name, email address, phone number, shipping
            address, and payment information.
          </li>
          <li>
            <strong>Order Information:</strong> Details of the products you
            purchase, including quantities and prices.
          </li>
          <li>
            <strong>Account Information:</strong> Information related to your
            account, such as order history and preferences.
          </li>
          <li>
            <strong>Usage Data:</strong> Information about how you use our Site,
            including IP address, browser type, referring sites, and pages
            visited.
          </li>
        </ul>

        <h2>3. How We Use Your Information</h2>
        <p>
          We use the information we collect for various purposes, including:
        </p>
        <ul>
          <li>
            <strong>Order Processing:</strong> To process and fulfill your
            orders, including handling payments, shipping, and providing
            customer service.
          </li>
          <li>
            <strong>Account Management:</strong> To create and manage your
            account, communicate important updates, and respond to inquiries.
          </li>
          <li>
            <strong>Marketing and Promotions:</strong> To send you promotional
            emails, newsletters, and offers based on your preferences. You can
            opt-out of marketing communications at any time.
          </li>
          <li>
            <strong>Site Improvement:</strong> To analyze usage patterns and
            improve our Site and services.
          </li>
          <li>
            <strong>Legal Compliance:</strong> To comply with legal obligations
            and enforce our policies.
          </li>
        </ul>

        <h2>4. How We Share Your Information</h2>
        <p>
          We do not sell or rent your personal information to third parties. We
          may share your information in the following situations:
        </p>
        <ul>
          <li>
            <strong>Service Providers:</strong> We may share your information
            with third-party service providers who assist us in operating our
            Site, processing payments, and fulfilling orders (e.g., payment
            processors, shipping companies).
          </li>
          <li>
            <strong>Legal Requirements:</strong> We may disclose your
            information if required by law, to comply with legal processes, or
            to protect the rights, property, or safety of our company or others.
          </li>
          <li>
            <strong>Business Transfers:</strong> In the event of a merger,
            acquisition, or sale of assets, your information may be transferred
            as part of the transaction.
          </li>
        </ul>

        <h2>5. Data Security</h2>
        <p>
          We implement reasonable security measures to protect your personal
          information from unauthorized access, use, or disclosure. However, no
          method of transmission over the Internet or electronic storage is
          completely secure, and we cannot guarantee absolute security.
        </p>

        <h2>6. Cookies and Tracking Technologies</h2>
        <p>
          Our Site uses cookies and similar tracking technologies to enhance
          your experience. Cookies are small files stored on your device that
          help us remember your preferences and track usage patterns. You can
          control and manage cookies through your browser settings.
        </p>

        <h2>7. Your Choices</h2>
        <ul>
          <li>
            <strong>Access and Update:</strong> You can access and update your
            personal information through your account on our Site or by
            contacting us directly.
          </li>
          <li>
            <strong>Opt-Out:</strong> You may opt-out of receiving marketing
            communications by following the unsubscribe instructions in the
            emails or contacting us.
          </li>
          <li>
            <strong>Do Not Track:</strong> Our Site does not respond to “Do Not
            Track” signals from your browser.
          </li>
        </ul>

        <h2>8. Children's Privacy</h2>
        <p>
          Our Site is not intended for children under the age of 18. We do not
          knowingly collect personal information from children under 18. If we
          become aware that we have collected such information, we will take
          steps to delete it.
        </p>

        <h2>9. International Users</h2>
        <p>
          If you access our Site from outside INDIA, please be aware that your
          information may be transferred to and processed in INDIA. By using our
          Site, you consent to the transfer of your information to INDIA.
        </p>

        <h2>10. Changes to This Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. Any changes will
          be posted on this page with an updated effective date. We encourage
          you to review this policy periodically for any updates.
        </p>

        <h2>11. Contact Us</h2>
        <p>
          If you have any questions or concerns about this Privacy Policy or our
          data practices, please contact us at:
        </p>
        <ul>
          <li>Email: info@royalindiansandalwood.com</li>
          <li>Phone: +91 9004015163</li>
          <li>
            Address: “ROYAL INDIAN SANDALWOOD PRIVATE LIMITED” Ward 9, Block 65
            2, Near Jindhal Srikanthapura, Bengaluru 560073, Karnataka, India.
          </li>
        </ul>
      </div>
      <Footer showBothDivs={false} />
    </Layout>
  );
};

export default PrivacyPolicy;
