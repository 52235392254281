import React from 'react'
import { useNavigate } from 'react-router-dom';
import './Handicrafts.css'
import handi_image from '../../Assets/handicrafts.jpg'
import handi_mobileimg from '../../Assets/handicraft_Mobile.png'

export const Handicrafts = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    window.scrollTo(0, 0);
    navigate('/products');
  };

  return (
    <div className='Handicrafts-main'>
    <div className='Handicrafts-first'>
      <img className='hndlg' src={handi_image} alt="img" />
      <img className='hndsm' src={handi_mobileimg} alt="img" />
      <div className="Handicrafts-content">
        <h1>Handicrafts</h1>
        <p>The Ayurvedic standard for beauty was always pure, fresh, seasonal and natural.</p>
        <button className='Handicrafts-btn' onClick={handleButtonClick}>EXPLORE PRODUCTS</button>
      </div>
    </div>
    </div>
  )
}

export default Handicrafts;