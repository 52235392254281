import React from "react";
// import "./Information.css";
import "./BookFlip.css";
import wooden_ganpati from "../../Assets/Wooden_Ganpati.jpg";
import women from "../../Assets/women.jpg";
import oil_bottle from "../../Assets/oil_bottle.jpg";
import chandan_lap from "../../Assets/chandan_lap.PNG";
import chandan_bowl from "../../Assets/chandan_bowl.jpg";
import chandan_tikka from "../../Assets/chandan_tikka.jpg";
import aggarbatti from "../../Assets/aggarbatti.jpg";
import temple from "../../Assets/temple.jpg";
import map from "../../Assets/map.jpg";

export const Information = () => {
  return (
    <div className="book">
      <div className="left-info-imges">
        <div className="row">
          <div className="column">
            <img
              src={wooden_ganpati}
              alt="wooden_ganpati"
              style={{ width: "100%", height: "165px" }}
            />
          </div>
          <div className="column">
            <img
              src={women}
              alt="women"
              style={{ width: "100%", height: "165px" }}
            />
          </div>
          <div className="column">
            <img
              src={oil_bottle}
              alt="oil_bottle"
              style={{ width: "100%", height: "165px" }}
            />
          </div>
        </div>
        <div className="row">
          <div className="column">
            <img
              src={chandan_lap}
              alt="chandan_lap"
              style={{ width: "100%", height: "165px" }}
            />
          </div>
          <div className="column">
            <img
              src={chandan_bowl}
              alt="chandan_bowl"
              style={{ width: "100%", height: "165px" }}
            />
          </div>
          <div className="column">
            <img
              src={chandan_tikka}
              alt="chandan_tikka"
              style={{ width: "100%", height: "165px" }}
            />
          </div>
        </div>
        <div className="row">
          <div className="column">
            <img
              src={aggarbatti}
              alt="aggarbatti"
              style={{ width: "100%", height: "165px" }}
            />
          </div>
          <div className="column">
            <img
              src={temple}
              alt="temple"
              style={{ width: "100%", height: "165px" }}
            />
          </div>
          <div className="column">
            <img
              src={map}
              alt="map"
              style={{ width: "100%", height: "165px" }}
            />
          </div>
        </div>
      </div>
      <div className="right-info">
        <div>
          <h6 style={{ fontSize: "30px", fontWeight: "100" }}>
            WHY SANDALWOOD?
          </h6>
        </div>
        <div className="text-p">
          <p>
            Indian Sandalwood (Santalum album L) or "Srigandha" or "Chandan" is
            the country's pride and symbolizes the Indian heritage and legacy.
          </p>
        </div>
        <div className="text-p">
          <p>
            <b>AROMATHERAPY:</b> Sandalwood essential oil is often used in
            aromatherapy for its relaxing and grounding qualities. It is
            believed to help reduce stress and anxiety, promoting a sense of
            tranquillity.
          </p>
        </div>
        <div className="text-p">
          <p>
            <b>FRAGRANCE:</b> Sandalwood is known for its unique, rich, sweet,
            and woody aroma. The essential oil extracted from sandalwood is
            widely used in perfumes, incense, and scented products. The
            enchanting fragrance and therapeutic properties of sandalwood brings
            tranquillity,stress relief and a touch of nature's luxury to your
            daily routine.
          </p>
        </div>
        <div className="text-p">
          <p>
            <b>MEDICINAL PROPERTIES:</b> Ayurveda and traditional Chinese
            medicine believes it to have properties like antiseptic,
            anti-inflammatory, and antispasmodic effects. It's used to ease
            conditions like anxiety, insomnia, skin irritations, and more.
          </p>
        </div>
        <div className="text-p">
          <p>
            <b>SKINCARE AND COSMETICS:</b> Sandalwood is used in various
            skincare and cosmetic products due to its anti-aging, soothing
            properties and potential benefits for the skin. It has
            anti-inflammatory and antiseptic properties, making it suitable for
            treating acne and other skin Issues.
          </p>
        </div>
        <div className="text-p">
          <p>
            <b>RELIGIOUS AND SPIRITUAL USE:</b> In many cultures and religions,
            sandalwood is considered sacred and is used in religious rituah,
            ceremo nies, and meditation practices. The fragrance is believed to
            have a calming effect on the mind. It is often used in the form of
            incense during meditation, prayer, and ceremonies to promote a sense
            of tranquillity and focus.
          </p>
        </div>
        <div className="text-p">
          <p>
            <b>CULTURAL SIGNIFICANCE:</b> Sandalwood has played a significant
            role in various cultures, particularly in regions like India, where
            it has been used in religious ceremonies, traditional medicines,
            cultural practices for centuries and as a mark of hospitality.
          </p>
        </div>
        <div className="text-p">
          <p>
            <b>WOODWORK AND CRAFTS:</b> The wood from the sandalwood tree is
            hard and durable, making it suitable for carving and woodworking The
            wood of the sandalwood tree is dense and fine-grained, making it
            ideal for carving intricate designs and sculptures. Intricate
            carvings sculptures and decorative items are often made from
            sandalwood.
          </p>
        </div>
        {/* <div className="info-logo">
          <img src={logo} alt="logo" />
        </div> */}
      </div>
    </div>
  );
};

export default Information;
