import React, { useState } from "react";
import Layout from "../Components/Layout/Layout";
import About from "../Components/Sub_Home/About";
import "../Styles/AboutPage.css";
import AboutPages from "../Components/Sub_Home/AboutPages";


const AboutPage = () => {
  const [cartItems, setCartItems] = useState([]);

  return (
    <Layout setCartItems={setCartItems} cartItems={cartItems} isFooter={true} isNavbar={true} >
      <About showAboutMainpg={false} />
      <AboutPages/>
    </Layout>
  );
};

export default AboutPage;
