import React, { useState, useEffect } from "react";
import Layout from "../../Components/Layout/Layout";
import UserMenu from "../../Components/Layout/UserMenu";
import { useAuth } from "../../Context/Auth";
import "./css/Dashboard.css";
import usericon from "../../Assets/dashUseIcon.png";
import mailicon from "../../Assets/dashMailIcon.png";
import phoneicon from "../../Assets/dashPhoneIcon.png";
import add1icon from "../../Assets/dashAddressoIcon.png";
import add2icon from "../../Assets/dashAddresstIcon.png";
import Footer from "../../Components/Layout/Footer";

const Dashboard = () => {
  const [auth] = useAuth();
  const [cartItems, setCartItems] = useState([]);
  const [showUserMenu, setShowUserMenu] = useState(false);
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const toggleUserMenu = () => {
    setShowUserMenu(!showUserMenu);
  };

  useEffect(() => {
    // Function to fetch profile data
    const fetchProfile = async () => {
      try {
        const response = await fetch('https://rispl.backendapihub.com/profile/view', {
          method: 'GET',
          headers: {
            'accept': 'application/json',
            'access-token': '2'
          }
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        if (data.status === "Success") {
          setProfile(data.data[0]);
        } else {
          throw new Error(data.message || 'Failed to fetch profile');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, []);

  return (
    <Layout
      setCartItems={setCartItems}
      cartItems={cartItems}
      isFooter={true}
      isNavbar={true}
    >
      <div className="dash-top-section"></div>
      <div className="dash-container">
        <div className="d-flex flex-lg-nowrap userActflexx">
          <UserMenu />

          <div className="drc d-containt">
            <h1 className="text-start p-0">Profile</h1>
            <hr className="dhr dhrn" />

            {/* Conditional rendering for loading and error messages */}
            {loading && <div className="loading-message">Loading...</div>}
            {error && <div className="error-message">Error: {error}</div>}

            {!loading && !error && profile && (
              <div className="details-container">
                <div className="d-lg-flex userMailspace">
                  <div className="detail w-50">
                    <img src={usericon} alt="" className="icon" />
                    <div className="info">
                      <span className="dashTitle">Name </span>
                      <span className="value">{profile.name}</span>
                    </div>
                  </div>
                  <div className="detail">
                    <img src={mailicon} alt="" className="icon" />
                    <div className="info">
                      <span className="dashTitle">Email Address </span>
                      <span className="value">{profile.email}</span>
                    </div>
                  </div>
                </div>
                <div className="detail">
                  <img src={phoneicon} alt="" className="icon" />
                  <div className="info">
                    <span className="dashTitle">Phone </span>
                    <span className="value">{profile.mobile}</span>
                  </div>
                </div>
                <hr className="dhr" />
                <div className="dab">
                  <div className="detail">
                    <img src={add1icon} alt="" className="icon" />
                    <div className="info">
                      <span className="dashTitle">Shipping Address </span>
                      <span className="value">
                        {profile.address[0]?.address1}, {profile.address[0]?.address2}
                      </span>
                    </div>
                  </div>
                  <div className="detail">
                    <img src={add2icon} alt="" className="icon" />
                    <div className="info">
                      <span className="dashTitle">Billing Address </span>
                      <span className="value">
                        {profile.address[1]?.address1}, {profile.address[1]?.address2}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
