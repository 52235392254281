import React from "react";
// import "./CoreTeam.css";
import "./BookFlip.css";
import ramaiah_chairman from "../../Assets/ramaiah-chairman.png";
import chandrashekar_f_director from "../../Assets/chandrashekar-f-director.png";
import ravikumar_f_director from "../../Assets/Ravikumar-f-director.png";
import rajan_m_director from "../../Assets/Rajan-m-director.png";
import logo from "../../Assets/logo.png";

export const CoreTeam = () => {
  return (
    <div className="book">
      <div className="left-core-imges">
        <div className="row top-img">
          <div className="column">
            <img
              src={ramaiah_chairman}
              alt="ramaiah-chairman"
              style={{ width: "190px", height: "175px" }}
            />
            <h5
              style={{
                fontSize: "15px",
                fontWeight: "700",
                margin: "6px 0 4px 25px",
              }}
            >
              Kondandaram Ramaiah
            </h5>
            <p style={{ fontSize: "11px", margin: "0 0 0 25px" }}>Chairman</p>
          </div>
          <div className="column">
            <img
              src={chandrashekar_f_director}
              alt="chandrashekar-f-director"
              style={{ width: "190px", height: "175px" }}
            />
            <h5
              style={{
                fontSize: "15px",
                fontWeight: "700",
                margin: "6px 0 4px 25px",
              }}
            >
              Dr. K T Chandrashekar
            </h5>
            <p style={{ fontSize: "11px", margin: "0 0 0 25px" }}>
              Founder Director
            </p>
          </div>
        </div>
        <div className="row top-img">
          <div className="column">
            <img
              src={ravikumar_f_director}
              alt="ravikumar-f-director"
              style={{ width: "190px", height: "175px" }}
            />
            <h5
              style={{
                fontSize: "15px",
                fontWeight: "700",
                margin: "6px 0 4px 25px",
              }}
            >
              G.Ravikumar
            </h5>
            <p style={{ fontSize: "11px", margin: "0 0 0 25px" }}>
              Founder Director
            </p>
          </div>
          <div className="column">
            <img
              src={rajan_m_director}
              alt="rajan-m-director"
              style={{ width: "190px", height: "175px" }}
            />
            <h5
              style={{
                fontSize: "15px",
                fontWeight: "700",
                margin: "6px 0 4px 25px",
              }}
            >
              Rajan B Shetty
            </h5>
            <p style={{ fontSize: "11px", margin: "0 0 0 25px" }}>
              Chief Executive Officer & Managing Director
            </p>
          </div>
        </div>
      </div>
      <div className="right-core-info">
        <div>
          <h6 style={{ fontSize: "30px", fontWeight: "100" }}>OUR CORE TEAM</h6>
        </div>
        <div>
          <p>
            <b>Kondandaram Ramaiah</b>/Chairman <br />
            Promoter and Managing Director of Ramalah Capital Private Limited He
            is son of Latte Dr. MS Ramah the well-new education an visionary
            entrepreneur of Kamataka who set up the Ramalah Group of Educational
            Institutions An entrepreneus, running numerous bal ness entities
            such as MS Ramaiah International Pvt Ltd, MSR Chemicals &
            Fertilizers Pvt. Ltd etc. MSR Biotech Pvt Ltd Heals the Dive tor of
            M/s. Ramaiah Educational Institutions and advisor to Gokula
            Education Foundation.
          </p>
        </div>
        <div>
          <p>
            <b>Dr. KT Chandrashekar</b>/Founder Director <br /> Master in
            Sandalwood anatomy and a specialist in the forest botany for 30+
            years He is one of those masters who have been part of the entire
            sandalwood journey from plantation to maintenance to harvesting
            "SRIGANDHAVANA- Awarded by Chief Monster of Karnataka for Lifetime
            achievement in Sandalwood research and development.
          </p>
        </div>
        <div>
          <p>
            <b>G. Ravikumar</b>/Founder Director <br />
            The Master Sandalwood researcher and a specialist in the chemistry
            of forestry products for 30+ years His everglary skin the fold
            Sandalwood aromatics and cosmetic products is a big contribution for
            our company Sandalwood oil extraction and distillation speculat who
            possesses a rare skill set specifically tailored to the intricate
            process of distilling Sandalwood seed oil He has published 120
            research articles, 2 books and 5 brochures "SANTALUM AWARD-Life Time
            Achievement award for contribution in sandalwood research and devel
            opment
          </p>
        </div>
        <div>
          <p>
            <b>Rajan B Shetty</b>/Chief Executive Officer and Managing Director{" "}
            <br />
            An engineer by qualification and senior management personnel from a
            very reputed MNC who has served in various signition ma agement
            roles for a span of 24 years A visonary entrepreneur who has
            embraced the role of a sandalwood farmer His journey in a texta ment
            to his belief in carving out a unique and unparalleled path
            Sandalwood farmer specializing in the entire life cycle of Sandalen
            from seed to pest management and owm. 1000+ sandalwood trees.
          </p>
        </div>
        {/* <div className="info-logo">
          <img src={logo} alt="logo" />
        </div> */}
      </div>
    </div>
  );
};
export default CoreTeam;
