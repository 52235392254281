import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import logo from "../../Assets/logo.png";
import logoSrc from "../../Assets/whiteLogo.png";
// import { useCart } from "../../Context/Cart";
import usericon from "../../Assets/userIcon.png";
import whiteuser from "../../Assets/whiteuser.png";
import whiteglob from "../../Assets/whiteglob.png";
import whitecart from "../../Assets/whitecart.png";
import Logo_Animation from "../../Assets/Logo_Animation.mp4";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWindowClose,
  faPlay,
  faShoppingBag,
} from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import Tooltip from "./UserTooltip";
import "./Navbar.css";

const Navbar = ({ cartCount, showNormalNav, textColor }) => {
  const [menu, setMenu] = useState("home");
  const [isVisible, setIsVisible] = useState(true);
  // const [cart] = useCart();
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();

  const textClass = textColor === "white" ? "whiteText" : "blackText";
  // const faShoppingBag = textColor === "white" ? whitecart : faShoppingBag;
  // const userIcon = textColor === "white" ? whiteuser : usericon;

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setIsVisible(
        currentScrollPos <= 0 || currentScrollPos < window.innerHeight
      );
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const addGoogleTranslateScript = () => {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src =
        "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
      script.async = true;
      document.body.appendChild(script);
    };

    window.googleTranslateElementInit = () => {
      new window.google.translate.TranslateElement(
        { pageLanguage: "en" },
        "google_translate_element"
      );
    };

    addGoogleTranslateScript();
  }, []);

  const userLinks = [
    { to: "/login", label: "Login" },
    { to: "/dashboard/user/profile", label: "Profile" },
    { to: "/dashboard/user/orderhistory", label: "Orders" },
    { to: "/dashboard/user/address", label: "Address" },
    // { to: "/payments", label: "Payments" },
  ];

  const closeModal = () => setShowModal(false);

  return (
    <>
      {showModal && (
        <div className="modal-overlay" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-buttonn" onClick={closeModal}>
              <FontAwesomeIcon icon={faWindowClose} />
            </button>
            <iframe
              width="560"
              height="315"
              src={Logo_Animation}
              title="Video"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      )}

      <div>
        {showNormalNav ? (
          <>
            {/* Top Navigation */}
            <div
              className={`d-flex justify-content-center align-items-center top-nav ${
                isVisible ? "" : "hide"
              }`}
            >
              <p className="m-0">WE DELIVER ACROSS INDIA & INTERNATIONALLY.</p>
            </div>

            {/* Main Navbar */}
            <nav className="navbar navbar-expand-lg p-0">
              <div className="container-lg justify-content-around">
                <div className="brand-menu">
                  <div className="logo">
                    <a
                      className="navbar-brand"
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowModal(true);
                      }}
                    >
                      <img
                        className="m-0"
                        src={textColor === "white" ? logoSrc : logo}
                        alt=""
                      />
                      <div className="play-button">
                        <FontAwesomeIcon icon={faPlay} />
                      </div>
                    </a>
                  </div>
                  <button
                    className="d-lg-none navbar-toggler collapsed d-flex flex-column"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="toggler-icon top-bar"></span>
                    <span className="toggler-icon middle-bar"></span>
                    <span className="toggler-icon bottom-bar"></span>
                  </button>
                </div>
                <div>
                  <div
                    className="collapse navbar-collapse custom-nav align-items-center"
                    id="navbarNav"
                  >
                    <ul className={`navbar-nav ${textClass}`}>
                      <li
                        onClick={() => setMenu("home")}
                        className={`nav-item px-1 ${
                          menu === "home" ? "active" : ""
                        }`}
                      >
                        <NavLink
                          to="/"
                          className={`nav-link ${textClass}`}
                          aria-current="page"
                        >
                          HOME
                        </NavLink>
                      </li>
                      <li
                        onClick={() => setMenu("about")}
                        className={`nav-item px-1 ${
                          menu === "about" ? "active" : ""
                        }`}
                      >
                        <NavLink
                          to="/about"
                          className={`nav-link ${textClass}`}
                          aria-current="page"
                        >
                          ABOUT
                        </NavLink>
                      </li>
                      <li
                        onClick={() => setMenu("service")}
                        className={`nav-item px-1 ${
                          menu === "service" ? "active" : ""
                        }`}
                      >
                        <NavLink
                          to="/Services"
                          className={`nav-link ${textClass}`}
                          aria-current="page"
                        >
                          SERVICES
                        </NavLink>
                      </li>
                      <li
                        onClick={() => setMenu("products")}
                        className={`nav-item px-1 ${
                          menu === "products" ? "active" : ""
                        }`}
                      >
                        <NavLink
                          to="/products"
                          className={`nav-link ${textClass}`}
                          aria-current="page"
                        >
                          PRODUCTS
                        </NavLink>
                      </li>

                      <div
                        className="d-lg-flex justify-content-around mt-2 nav-right"
                        style={{ gap: "4rem" }}
                      >
                        <Tooltip
                          //  icon={userIcon}
                          icon={<FontAwesomeIcon icon={faUser} />}
                          links={userLinks}
                        />
                      </div>
                    </ul>
                  </div>
                </div>
                <div id="google_translate_element"></div> 
                <NavLink to="/cart" className={`nav-link ${textClass}`}>
                  <div className="cart-icon sett">
                    <FontAwesomeIcon
                      icon={faShoppingBag}
                      style={{
                        fontSize: "20px",
                        color: textColor === "white" ? "#fff" : "#000",
                      }}
                    />
                    <span>{cartCount}</span>
                  </div>
                </NavLink>
              </div>
            </nav>
          </>
        ) : (
          <nav
            className="navbar navbar-expand-lg p-0"
            style={{ background: "#f1c40f", height: "80px" }}
          >
            <div className="container-lg justify-content-around">
              <div className="brand-menu">
                <div className="logo">
                  <a
                    className="navbar-brand"
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setShowModal(true);
                    }}
                  >
                    <img className="m-0" src={logoSrc} alt="" />
                  </a>
                </div>
                <button
                  className="d-lg-none navbar-toggler collapsed d-flex flex-column"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNav"
                  aria-controls="navbarNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="toggler-icon top-bar"></span>
                  <span className="toggler-icon middle-bar"></span>
                  <span className="toggler-icon bottom-bar"></span>
                </button>
              </div>
              <div>
                <div
                  className="collapse navbar-collapse gap-4 custom-nav align-items-center"
                  id="navbarNav"
                >
                  <ul className={`navbar-nav ${textClass}`}>
                    <li
                      onClick={() => setMenu("home")}
                      className={`nav-item px-1 ${
                        menu === "home" ? "active" : ""
                      }`}
                    >
                      <NavLink
                        to="/"
                        className={`nav-link ${textClass}`}
                        aria-current="page"
                      >
                        HOME
                      </NavLink>
                    </li>
                    <li
                      onClick={() => setMenu("about")}
                      className={`nav-item px-1 ${
                        menu === "about" ? "active" : ""
                      }`}
                    >
                      <NavLink
                        to="/about"
                        className={`nav-link ${textClass}`}
                        aria-current="page"
                      >
                        ABOUT
                      </NavLink>
                    </li>
                    <li
                      onClick={() => setMenu("service")}
                      className={`nav-item px-1 ${
                        menu === "service" ? "active" : ""
                      }`}
                    >
                      <NavLink
                        to="/Services"
                        className={`nav-link ${textClass}`}
                        aria-current="page"
                      >
                        SERVICES
                      </NavLink>
                    </li>
                    <li
                      onClick={() => setMenu("products")}
                      className={`nav-item px-1 ${
                        menu === "products" ? "active" : ""
                      }`}
                    >
                      <NavLink
                        to="/products"
                        className={`nav-link ${textClass}`}
                        aria-current="page"
                      >
                        PRODUCTS
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              <div id="google_translate_element"></div> 
              <NavLink to="/cart" className={`nav-link ${textClass}`}>
                <div className="cart-icon sett">
                  <FontAwesomeIcon
                    icon={faShoppingBag}
                    style={{
                      fontSize: "20px",
                      color: textColor === "white" ? "#fff" : "#000",
                    }}
                  />
                  <span>{cartCount}</span>
                </div>
              </NavLink>
            </div>
          </nav>
        )}
      </div>
    </>
  );
};

export default Navbar;
