import React from "react";
import "../.././Styles/ServicesPage.css";
import bubble_1 from "../../Assets/bubble1.png";
import perlimg from "../../Assets/pearl.png";
import r_leaf from "../../Assets/r_leaf.png";
import Tree from "../../Assets/Tree.png";
import servicespage1 from "../.././Assets/servicespage1.jpeg";
import servicespage2 from "../.././Assets/servicespage2.jpeg";
import { useNavigate } from "react-router-dom";

const ServicesPage = () => {
  const navigate = useNavigate();

  const handleClick1 = () => {
    window.scrollTo(0, 0);
    navigate(`/PlantationServices`);
  };
  const handleClick2 = () => {
    window.scrollTo(0, 0);
    navigate(`/FarmlandPlantation`);
  };
  const handleClick3 = () => {
    window.scrollTo(0, 0);
    navigate(`/Training_Modules`);
  };
  const handleClick4 = () => {
    window.scrollTo(0, 0);
    navigate(`/productlist`);
  };
  return (
    <>
      <div className="servicebg row position-relative">
        {/* <span>
          <img className="perl-img d-md" src={perlimg} alt="" />
        </span>
        <span>
          <img className="r-leaf d-md" src={r_leaf} alt="" />
        </span>
        <img className="bubble" id="bubbleone" src={bubble_3} alt="" />
        <img className="bubble" id="bubblefour" src={bubble_1} alt="" /> */}
      </div>
      <div className="services-container">
        <div className="services-content">
          <h1 className="services-title">Services we offer</h1>
          <p className="services-description">
            As a pioneering Indian company, we specialize in eco-friendly Indian
            sandalwood services and products. Our team, composed of sandalwood
            industry veteran scientists with over 30 years of experience, has
            elevated sandalwood cultivation to new heights. Our commitment to
            excellence and quality ensures only the finest Indian sandalwood,
            from seed selection to plantation management. Ethics and integrity
            are at the core of our operations, fostering trust and long-term
            relationships with our customers. We are the reliable source for
            premium sandalwood.
          </p>
        </div>
      </div>
      <div className="t-box">
        <div className="first-circle">
          <div className="second-circle">
            <span className="third-circle"></span>
            <span className="four-circle">
              <img className="tree-four" src={Tree} alt="Tree" />
              {/* <button className="all-button">ALL SERVICES</button> */}
            </span>
          </div>
          <div className="top-box" onClick={handleClick1}>
            <span className="dot-1"></span>
            <img
              className="img1"
              id="img-1"
              src={servicespage1}
              alt="Plantation service"
            />
            <h2>Plantation Model</h2>
          </div>
          <div className="left-box" onClick={handleClick2}>
            <span className="dot-2"></span>
            <img
              className="img2"
              id="img-2"
              src={servicespage1}
              alt="Sandalwood Plantation"
            />
            <h2>Sandalwood Plantation</h2>
          </div>
          <div className="right-box" onClick={handleClick3}>
            <span className="dot-3"></span>
            <img
              className="img3"
              id="img-3"
              src={servicespage2}
              alt="Training Modules"
            />
            <h2>Training</h2>
          </div>
          <div className="bottom-box" > 
            <span className="dot-4"></span>
            <img
              className="img4"
              id="img-4"
              src={servicespage2}
              alt="Heart wood assessment"
            />
            <h2>Heart wood assessment</h2>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesPage;
