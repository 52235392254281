import React, { useState } from "react";
import "../.././Styles/ProductssPage.css";
import bubble_1 from "../../Assets/bubble1.png";
import bubble_3 from "../../Assets/bubble3.png";
import perlimg from "../../Assets/pearl.png";
import r_leaf from "../../Assets/r_leaf.png";
import Tree from "../../Assets/Tree.png";
import PRoduct1 from "../.././Assets/PRoduct1.png";
import PRoduct2 from "../.././Assets/PRoduct2.png";
import PRoduct3 from "../.././Assets/PRoduct3.png";
import PRoduct4 from "../.././Assets/product_3.jpg";
import PRoduct5 from "../.././Assets/tissue_mob.png";
import Layout from "../.././Components/Layout/Layout";


const ProductssPage = () => {
  const [cartItems, setCartItems] = useState([]);

  return (
    <Layout setCartItems={setCartItems} cartItems={cartItems} isFooter={true} isNavbar={true}> 
      <div className="Productbg row position-relative">
        <span>
          <img className="Perl-img d-md" src={perlimg} alt="" />
        </span>
        <span>
          <img className="R-leaf d-md" src={r_leaf} alt="" />
        </span>
        <img className="Bubble" id="Bubbleone" src={bubble_3} alt="" />
        <img className="Bubble" id="Bubblefour" src={bubble_1} alt="" />
      </div>
      <div className="Product-container">
        <div className="Product-content">
          <h1 className="Product-title">Products we offer</h1>
          <p className="Product-description">
            As a pioneering Indian company, we specialize in eco-friendly Indian
            sandalwood services and products. Our team, composed of sandalwood
            industry veteran scientists with over 30 years of experience, has
            elevated sandalwood cultivation to new heights. Our commitment to
            excellence and quality ensures only the finest Indian sandalwood,
            from seed selection to plantation management. Ethics and integrity
            are at the core of our operations, fostering trust and long-term
            relationships with our customers. We are the reliable source for
            premium sandalwood.
          </p>
        </div>
      </div>
      <div className="T-box">
        <div className="First-circle">
          <div className="Second-circle">
            <span className="Third-circle"></span>
            <span className="Four-circle">
            <img className="tree-Four" src={Tree} alt="Tree" />
            <button className="all-btn">All Products</button>
            </span>
          </div>
          <div className="Top-box">
            <span className="Dot-1"></span>
            <img
              className="Img1"
              id="Img-1"
              src={PRoduct1}
              alt="Plantation service"
            />
            <h2>Customised handcrafts</h2>
          </div>
          <div className="Left-box">
            <span className="Dot-2"></span>
            <img
              className="Img2"
              id="Img-2"
              src={PRoduct3}
              alt="Sandalwood Plantation"
            />
            <h2>Wooden jewellary</h2>
          </div>
          <div className="Right-box">
            <span className="Dot-3"></span>
            <img
              className="Img3"
              id="Img-3"
              src={PRoduct2}
              alt="Training Modules"
            />
            <h2>Cosmetics</h2>
          </div>
          <div className="Right-box2">
            <span className="Dot-5"></span>
            <img
              className="Img5"
              id="Img-5"
              src={PRoduct5}
              alt="Training Modules"
            />
            <h2>Tissucultured sandalwood plant</h2>
          </div>
          <div className="Bottom-box">
            <span className="Dot-4"></span>
            <img
              className="Img4"
              id="Img-4"
              src={PRoduct4}
              alt="Heart wood assessment"
            />
            <h2>Perfume, Agarbatti, Soap</h2>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ProductssPage;
