import React from "react";
// import "./Maintenance.css";
import "./BookFlip.css";
import Maintenance_1 from "../../Assets/Maintenance_1.jpg";
import Maintenance_2 from "../../Assets/Maintenance_2.jpg";
import Maintenance_3 from "../../Assets/Maintenance_3.jpg";
import Maintenance_4 from "../../Assets/Maintenance_4.jpg";
import logo from "../../Assets/logo.png";

export const Maintenance = () => {
  return (
    <div className="book">
      <div className="left-main-imges">
        <div className="row">
          <div className="column">
            <img
              src={Maintenance_1}
              alt="water-pipe"
              style={{ width: "100%", height: "248px" }}
            />
          </div>
          <div className="column">
            <img
              src={Maintenance_2}
              alt="Scissors"
              style={{ width: "100%", height: "248px" }}
            />
          </div>
        </div>
        <div className="row">
          <div className="column">
            <img
              src={Maintenance_3}
              alt="Kalam"
              style={{ width: "100%", height: "248px" }}
            />
          </div>
          <div className="column">
            <img
              src={Maintenance_4}
              alt="Contaract"
              style={{ width: "100%", height: "248px" }}
            />
          </div>
        </div>
      </div>
      <div className="right-main-info">
        <div>
          <h6 style={{ fontSize: "50px", fontWeight: "100" }}>
            SANDALWOOD MAINTENANCE
          </h6>
        </div>
        <div>
          <p>
            Sandalwood trees (Santalum album) are highly valued for their
            aromatic heartwood, used in perfumes, cosmetics, and traditional
            medicine. Proper maintenance is crucial for their growth and the
            production of high-quality sandalwood. The company enters into an
            Annual Mainte nance Contract with the client on a yearly basis. The
            AMC clearly states the frequency of site visit, pest and disease
            management, soil health management, support and staking, monitoring
            growth, record keeping, emergency response, reporting, Contract
            duration and renewal etc.
          </p>
        </div>
        <div className="info-main-logo">
          <img src={logo} alt="logo" />
        </div>
      </div>
    </div>
  );
};
export default Maintenance;
