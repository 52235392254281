import React from "react";
// import "./Development.css";
import "./BookFlip.css";
import Development_1 from "../../Assets/Development_1.jpg";
import Development_2 from "../../Assets/Development_2.jpg";
import Development_3 from "../../Assets/Development_3.jpg";
import Development_4 from "../../Assets/Development_4.jpg";
import logo from "../../Assets/logo.png";

export const Development = () => {
  return (
    <div className="book">
      <div className="left-dev-imges">
        <div className="row">
          <div className="column">
            <img
              src={Development_1}
              alt="people meeting"
              style={{ width: "100%", height: "248px" }}
            />
          </div>
          <div className="column">
            <img
              src={Development_2}
              alt="at-farm-discussion"
              style={{ width: "100%", height: "248px" }}
            />
          </div>
        </div>
        <div className="row">
          <div className="column">
            <img
              src={Development_3}
              alt="plant-doctor-magnifying-glass"
              style={{ width: "100%", height: "248px" }}
            />
          </div>
          <div className="column">
            <img
              src={Development_4}
              alt="laptop-meeting"
              style={{ width: "100%", height: "248px" }}
            />
          </div>
        </div>
      </div>
      <div className="right-dev-info">
        <div>
          <h6
            style={{
              fontSize: "44px",
              fontWeight: "100",
              // lineHeight: "65px",
            }}
          >
            TRAINING <br /> AND SKILL DEVELOPMENT
          </h6>
        </div>
        <div>
          <p>
            Sandalwood farming involves the cultivation of sandalwood trees for
            their valuable heartwood, which is used in various industries,
            includ ing perfumery, medicine, and religious rituals. To succeed in
            this field, proper training and skill development are crucial Here's
            a brief over view of the key aspects:
          </p>
        </div>
        <div className="text-dev-p">
          <p>
            <b>1. Botanical Knowledge:</b> Our training encompasses an
            understanding of sandalwood botany, growth patterns, and
            environmental requirements. Knowledge about different sandalwood
            species and their specific traits is essential for successful
            cultivation.
          </p>

          <p>
            <b>2. Site Selection and Preparation:</b>We teach various aspects of
            right site with appropriate soill conditions, climate, and water. We
            enhance and upgrade the skill in preparing the land through soil
            testing, drainage setup, and other site-specific requirements.
          </p>

          <p>
            <b>3. Nurturing Young Plants:</b>Training covers the care of young
            sandalwood plants, including proper watering, fertilization, pest
            and disease management, and protection from adverse weather
            conditions.
          </p>

          <p>
            <b>4. Plantation Management:</b>Developing skills in managing larger
            sandalwood plantations involves efficient irrigation systems, pest
            control strategies, weed management, and sustainable harvesting
            practices.
          </p>

          <p>
            <b>5. Pruning and Thinning:</b>Properly pruning and thinning the
            sandalwood trees at the right stages is an art that enhances
            heartwood formation and quality. This skill contributes to
            maximizing yield.
          </p>

          <p>
            <b>6. Financial and Business Skills:</b> Training on understanding
            the economic aspects of sandalwood farming, including budgeting,
            market trends, and value chain dynamics, which is essential for
            long-term success.
          </p>
        </div>
        {/* <div className="info-dev-logo">
          <img src={logo} alt="logo" style={{ marginTop: "" }} />
        </div> */}
      </div>
    </div>
  );
};
export default Development;
