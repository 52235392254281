import React from "react";
// import "./WhyChooseUs.css";
import "./BookFlip.css";
import five_star from "../../Assets/five_star.jpg";
import whychoose_img from "../../Assets/whychoose-img.jpg";
import leaf_img from "../../Assets/leaf-img.jpg";
import about_img from "../../Assets/aboutimg.jpg";
import money_plant from "../../Assets/money-plant.jpg";
import trees_img from "../../Assets/trees-img.jpg";
import chandan_wood from "../../Assets/chandan-wood.jpg";
import five_hundred_img from "../../Assets/five-hundred-img.jpg";
import perfume_img from "../../Assets/perfume-img.jpg";
// import logo from "../../Assets/logo.png";

export const WhyChooseUs = () => {
  return (
    <div className="book">
      <div className="left-imges">
        <div className="row">
          <div className="column">
            <img
              src={five_star}
              alt="five_star"
              style={{ width: "100%", height: "165px" }}
            />
          </div>
          <div className="column">
            <img
              src={whychoose_img}
              alt="whychoose_img"
              style={{ width: "100%", height: "165px" }}
            />
          </div>
          <div className="column">
            <img
              src={leaf_img}
              alt="leaf_img"
              style={{ width: "100%", height: "165px" }}
            />
          </div>
        </div>
        <div className="row">
          <div className="column">
            <img
              src={about_img}
              alt="about_img"
              style={{ width: "100%", height: "165px" }}
            />
          </div>
          <div className="column">
            <img
              src={money_plant}
              alt="money_plant"
              style={{ width: "100%", height: "165px" }}
            />
          </div>
          <div className="column">
            <img
              src={trees_img}
              alt="trees_img"
              style={{ width: "100%", height: "165px" }}
            />
          </div>
        </div>
        <div className="row">
          <div className="column">
            <img
              src={chandan_wood}
              alt="chandan_wood"
              style={{ width: "100%", height: "165px" }}
            />
          </div>
          <div className="column">
            <img
              src={five_hundred_img}
              alt="five_hundred_img"
              style={{ width: "100%", height: "165px" }}
            />
          </div>
          <div className="column">
            <img
              src={perfume_img}
              alt="perfume_img"
              style={{ width: "100%", height: "165px" }}
            />
          </div>
        </div>
      </div>
      <div className="right-w-chosse-info">
        <div>
          <h6 style={{ fontSize: "30px", fontWeight: "100" }}>
            WHY CHOOSE U$?
          </h6>
        </div>
        <div className="text-w-choose-p">
          <p>
            <b>Mastery in Sandalwood:</b> Our partner scientists with profound
            knowledge and insights have not only witnessed the mitul stages of
            sandalwood cultivation but have also experienced the culmination of
            the entire lifecycle-from planting to harvesting. Our comprehen sive
            understanding of the challenges, intricacies, and timing involved in
            each stage of the sandalwood lifecycle empowers us to make informed
            decisions and implement strategies that maximize the potential of
            the sandalwood plantations.
          </p>
        </div>
        <div className="text-w-choose-p">
          <p>
            <b>Sandalwood Farming Facilitation:</b> Hand-holding throughout the
            journey of sandalwood farming by way of encouragement, guidance
            mentorship and Supportive care.
          </p>
        </div>
        <div className="text-w-choose-p">
          <p>
            <b>Yield-Focused Sandalwood Nursery:</b> A crucial initial step
            before embarking on the journey of sandalwood plantation is securing
            an excellent source of sandalwood saplings, which ultimately
            determines the quality of the yield during the harvesting phase. The
            specialand sandalwood saplings available at our nursery stand out
            due to their exceptional quality, promising a substantial heartwood
            yeld upon maturation over the years.
          </p>
        </div>
        <div className="text-w-choose-p">
          <p>
            <b>Innovative Scientific plantation methodology:</b> Our company
            takes pride in introducing a distinctive plantation methodology that
            not only guarantees optimal growth and results from each tree but
            abo prioritizes security and protection.
          </p>
        </div>
        <div className="text-w-choose-p">
          <p>
            <b>Custom crafting of host plant:</b> We create tailor-made host
            plant selections that align with your unique environment and
            preferences. Through comprehensive analysis, we identify the host
            plants that are not only suited to the environment but are also
            poised to flourish and enhance the natural beauty of your space.
          </p>
        </div>
        <div className="text-w-choose-p">
          <p>
            <b>Specialization of Heartwood assessment Technique:</b> Our team of
            experts are well-versed in the intricate science of the evaluation
            of the heartwood content within the sandalwood tree to determine its
            quality and quantity, which is crucial for sustainable harvesting
            practices.
          </p>
        </div>
        <div className="text-w-choose-p">
          <p>
            <b>Profitable Financial Models:</b> We acknowledge the individuality
            of each person's financial path, which is why we provide a wide
            array of income modes tailored to suit your short, medium, and
            long-term financial aspirations.
          </p>
        </div>
        <div className="text-w-choose-p">
          <p>
            <b>Aesthetic Enhancement:</b> Beyond plantation, our choices
            contribute to the aesthetic beauty of your space, enhancing its
            overall appeal.
          </p>
        </div>
        {/* <div className="info-logo">
          <img src={logo} alt="logo" />
        </div> */}
      </div>
    </div>
  );
};
export default WhyChooseUs;
