import React from 'react';
import { useLocation } from 'react-router-dom'; // Import the useLocation hook
import '../Styles/PaymentResult.css'; // Use the same CSS file

const PaymentFailurePage = () => {
  const location = useLocation();
  const { transactionId, amount } = location.state || {};

  return (
    <div className="containerr">
      <div className="printer-top"></div>

      <div className="paper-container">
        <div className="printer-bottom"></div>

        <div className="paperr">
          <div className="main-contents">
            <div className="failure-icon">&#10060;</div>
            <div className="failure-title">Payment Failed</div>
            <div className="failure-description">
              Unfortunately, your payment could not be processed. Please try again later or contact support.
            </div>
            <div className="order-details">
              <div className="order-number-label">Transaction ID</div>
              <div className="order-number">{transactionId}</div>
              <div className="order-number-label">Amount Attempted</div>
              <div className="order-number">₹{amount}</div>
              <div className="complement">We apologize for the inconvenience.</div>
            </div>
          </div>
          <div className="jagged-edge"></div>
        </div>
      </div>
    </div>
  );
};

export default PaymentFailurePage;
