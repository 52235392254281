import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import "./UserTooltip.css";

const Tooltip = ({ links }) => {
  return (
    <div className="tooltip-container">
      <FontAwesomeIcon icon={faUser} style={{fontSize:"20px"}} />
      <div className="tooltip-content">
        {links.map((link, index) => (
          <React.Fragment key={index}>
            <Link to={link.to}>{link.label}</Link>
            {index < links.length - 1 && <div className="tooltip-divider"></div>}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default Tooltip;
