import React, { useRef, useState } from "react";
import Slider from "react-slick";
import img1 from "../Assets/cart-img1.png";
import img2 from "../Assets/product_1.jpg";
import img3 from "../Assets/product_3.jpg";
import img4 from "../Assets/product_4.jpg";
import left_button from "../Assets/left_arrow.png";
import right_button from "../Assets/right_arrow.png";
import flwr from "../Assets/best-selling-flower.png";
import "../Styles/ProductItem.css";

const SimilarProduct = ({ products }) => {
  let sliderRef = useRef(null);

  const next = () => {
    sliderRef.slickNext();
  };

  const previous = () => {
    sliderRef.slickPrev();
  };

  const settings = {
    autoplay: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
     <div
          className="container d-flex justify-content-between px-5"
          id="smpdh"
        >
          <h1 className="m-0 bst bs-title">Similar products</h1>
          {products.length > 4 && (
          <div className="iconicss" id="smpdbtn">
            <img
              className="p-2 bs-leftbtn"
              src={left_button}
              type="button"
              data-bs-target="#carouselExample"
              data-bs-slide="prev"
              onClick={previous}
            />
            <img
              className="p-2 bs-rightbtn"
              src={right_button}
              type="button"
              data-bs-target="#carouselExample"
              data-bs-slide="next"
              onClick={next}
            />
          </div>
        )}
        </div>

        <div className="category-main-container">
          <div className="zxc container position-relative m-0">
            <img className="best-seslling-flowerr" src={flwr} alt="" />
            <Slider
            ref={(slider) => {
              sliderRef = slider;
            }}
            {...(products.length > 4 ? settings : { slidesToShow: products.length, slidesToScroll: 1 })}
          >
             {products.map((product, index) => (
              <div className="item-box" key={index}>
                <div className="card">
                  <img
                    src={`https://rispl.backendapihub.com/images/${product.medianame}`}
                    className="img-fluid slider-image"
                    alt={product.product_name}
                  />
                  <div className="card-body">
                    <h5 className="bs-card-title">{product.product_name}</h5>
                    <span className="bs-price">₹ {product.price}</span>
                  </div>
                </div>
              </div>
            ))}
            </Slider>
          </div>
        </div>

        <div className="ctnrev">
          <div className="container d-flex justify-content-between">
            <h1 className="m-0 hdbtnn">Customer review</h1>
            <div className="crlbtnn">
              <img
                className="p-2"
                src={left_button}
                type="button"
                data-bs-target="#carouselExampleCustomerReview"
                data-bs-slide="prev"
              />
              <img
                className="p-2"
                src={right_button}
                type="button"
                data-bs-target="#carouselExampleCustomerReview"
                data-bs-slide="next"
              />
            </div>
          </div>
          <div
            id="carouselExampleCustomerReview"
            class="carousel slide px-3 gpgp"
          >
            <div class="carousel-inner">
              <div class="review carousel-item active">
                <div className="row p-5">
                  <div className="col-4  p-3">
                    <p class="rating">
                      <span>&#9734;</span>
                      <span>&#9734;</span>
                      <span>&#9734;</span>
                      <span>&#9734;</span>
                      <span>&#9734;</span>
                    </p>
                    <h5>So unique and earthy</h5>
                    <p className="rvw">
                      The best part about the set was the stitching. I've never
                      been happier about a stitching ever. And the print is so
                      earthy and the neck cut gives it the perfect modern touch.
                      Absolutely love it
                    </p>
                    <h6>Shanta narendranath</h6>
                  </div>

                  <div className="col-4 p-3">
                    <p class="rating">
                      <span>&#9734;</span>
                      <span>&#9734;</span>
                      <span>&#9734;</span>
                      <span>&#9734;</span>
                      <span>&#9734;</span>
                    </p>
                    <h5>Great Product</h5>
                    <p className="rvw">
                      The kaftans i ordered were of extremely high quality
                      comfortable and adds grace to the personality. Very
                      satisfied with every purchase from the Indian ethnic
                      sandalwood are...
                    </p>
                    <h6>Shanta narendranath</h6>
                  </div>

                  <div className="col-4 p-3">
                    <p class="rating">
                      <span>&#9734;</span>
                      <span>&#9734;</span>
                      <span>&#9734;</span>
                      <span>&#9734;</span>
                      <span>&#9734;</span>
                    </p>
                    <h5>Stunning</h5>
                    <p className="rvw">
                      I love it everybit of the shopping experience- from how
                      the products are cater to how easy to navigate the website
                      to how prompt the delivery was. I will be a lifelong
                      customer, hopefully...
                    </p>
                    <h6>Shanta narendranath</h6>
                  </div>
                </div>
              </div>

              <div class="review carousel-item">
                <div className="row p-5">
                  <div className="col-4  p-3">
                    <p class="rating">
                      <span>&#9734;</span>
                      <span>&#9734;</span>
                      <span>&#9734;</span>
                      <span>&#9734;</span>
                      <span>&#9734;</span>
                    </p>
                    <h5>So unique and earthy</h5>
                    <p className="rvw">
                      The best best part about the set was the stitching. I've
                      never been happier about a stitching ever. And the print
                      is so earthy and the neck cut gives it the perfect modern
                      touch. Absolutely love it
                    </p>
                    <h6>Shanta narendranath</h6>
                  </div>

                  <div className="col-4 p-3">
                    <p class="rating">
                      <span>&#9734;</span>
                      <span>&#9734;</span>
                      <span>&#9734;</span>
                      <span>&#9734;</span>
                      <span>&#9734;</span>
                    </p>
                    <h5>Great Product</h5>
                    <p className="rvw">
                      The kaftans i ordered were of extremely high quality
                      comfortable and adds grace to the personality. Very
                      satisfied with every purchase from the Indian ethnic co
                    </p>
                    <h6>Shanta narendranath</h6>
                  </div>

                  <div className="col-4 p-3">
                    <p class="rating">
                      <span>&#9734;</span>
                      <span>&#9734;</span>
                      <span>&#9734;</span>
                      <span>&#9734;</span>
                      <span>&#9734;</span>
                    </p>
                    <h5>Stunning</h5>
                    <p className="rvw">
                      I love it everybit of the shopping experience- from how
                      the products are catalogued to how easy it is to navigate
                      the website to how prompt the delivery was. I will be a
                      lifelong customer, hopefully...
                    </p>
                    <h6>Shanta narendranath</h6>
                  </div>
                </div>
              </div>

              <div class="review carousel-item">
                <div className="row p-5">
                  <div className="col-4  p-3">
                    <p class="rating">
                      <span>&#9734;</span>
                      <span>&#9734;</span>
                      <span>&#9734;</span>
                      <span>&#9734;</span>
                      <span>&#9734;</span>
                    </p>
                    <h5>So unique and earthy</h5>
                    <p className="rvw">
                      The best best part about the set was the stitching. I've
                      never been happier about a stitching ever. And the print
                      is so earthy and the neck cut gives it the perfect modern
                      touch. Absolutely love it
                    </p>
                    <h6>Shanta narendranath</h6>
                  </div>

                  <div className="col-4 p-3">
                    <p class="rating">
                      <span>&#9734;</span>
                      <span>&#9734;</span>
                      <span>&#9734;</span>
                      <span>&#9734;</span>
                      <span>&#9734;</span>
                    </p>
                    <h5>Great Product</h5>
                    <p className="rvw">
                      The kaftans i ordered were of extremely high quality
                      comfortable and adds grace to the personality. Very
                      satisfied with every purchase from the Indian ethnic co
                    </p>
                    <h6>Shanta narendranath</h6>
                  </div>

                  <div className="col-4 p-3">
                    <p class="rating">
                      <span>&#9734;</span>
                      <span>&#9734;</span>
                      <span>&#9734;</span>
                      <span>&#9734;</span>
                      <span>&#9734;</span>
                    </p>
                    <h5>Stunning</h5>
                    <p className="rvw">
                      I love it everybit of the shopping experience- from how
                      the products are catalogued to how easy it is to navigate
                      the website to how prompt the delivery was. I will be a
                      lifelong customer, hopefully...
                    </p>
                    <h6>Shanta narendranath</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  );
};

export default SimilarProduct;
