import React from "react";
import "./Offers.css";
import exclusive_image1 from "../../Assets/product-img.jpg";
import exclusive_image3 from "../../Assets/service-img.jpg";
import { useNavigate } from "react-router-dom";

export const Offers = () => {
  const navigate = useNavigate();

  const handleClick1 = () => {
    window.scrollTo(0, 0);
    navigate(`/Services`);
  };
  const handleClick2 = () => {
    window.scrollTo(0, 0);
    navigate(`/Products`);
  };

  return (
    <div className="offers d-flex">
      <div className="offers-right">
        <img src={exclusive_image3} alt="img2" />
        <h3 className="service">Services</h3>
        <a onClick={handleClick1} className="offerbtn btn btn-right">
          EXPLORE MORE
        </a>
      </div>
      <div className="offers-left">
        <img src={exclusive_image1} alt="img1" />
        <h3 className="producthm">Product</h3>
        <a onClick={handleClick2} className="offerbtn btn btn-left">
          EXPLORE MORE
        </a>
      </div>
    </div>
  );
};

export default Offers;
