import { Routes, Route } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import AboutPage from "./Pages/AboutPage";
import ServicePage from "./Pages/ServicePage";
import HandcraftsPage from "./Pages/HandcraftsPage";
import Gifting from "./Pages/Gifting";
import Register from "./Pages/Auth/Register";
import Login from "./Pages/Auth/Login";
import ForgotPasssword from "./Pages/Auth/ForgotPassword";
import AdminOrders from "./Pages/Admin/AdminOrders";
import Users from "./Pages/Admin/Users";
import Products from "./Pages/Admin/Products";
import UpdateProduct from "./Pages/Admin/UpdateProduct";
import CreateProduct from "./Pages/Admin/CreateProduct";
import CreateCategory from "./Pages/Admin/CreateCategory";
import AdminDashboard from "./Pages/Admin/AdminDashboard";
import PrivateRoute from "./Components/Routes/Private";
import AdminRoute from "./Components/Routes/AdminRoute";
import Dashboard from "./Pages/User/Dashboard";
import Orders from "./Pages/User/Orders";
import ProductDetails from "./Pages/ProductDetails";
import Categories from "./Pages/Categories";
import CartPage from "./Pages/CartPage";
import CategoryProduct from "./Pages/CategoryProduct";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import Wishlist from "./Pages/User/Wishlist";
import ChangePswd from "./Pages/User/ChangePswd";
import ProductPage from "./Pages/ProductList";
import { Toaster } from "react-hot-toast";
import CheckoutPage from "./Pages/CheckoutPage";
import Productpagemain from "./Pages/Productpagemain";
import PlantationServices from "./Components/Sub_Home/PlantationService";
import Farmland from "./Components/Sub_Home/Farmland";
import Villa from "./Components/Sub_Home/Villa";
import ProductssPage from "./Components/Sub_Home/ProductsPage";
import Training from "./Components/Sub_Home/Training";
import PaymentFailurePage from "./Pages/PaymentFailurePage";
import PaymentSuccessPage from "./Pages/PaymentSuccessPage";
import Address from "./Pages/User/Address";
import ServicesPage from "./Components/Sub_Home/ServicesPage";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import ReturnRefundPolicy from "./Pages/ReturnRefundPolicy";
import TermsAndConditions from "./Pages/TermsAndConditions";

function App() {
  return (
    <>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/PaymentSuccess" element={<PaymentSuccessPage />} />
          <Route path="/PaymentFailure" element={<PaymentFailurePage />} />
          <Route path="/products" element={<Productpagemain />} />
          <Route path="/PlantationServices" element={<PlantationServices />} />
          <Route path="/FarmlandPlantation" element={<Farmland />} />
          <Route path="/Villa_Bunglow" element={<Villa />} />
          <Route path="/Training_Modules" element={<Training />} />
          <Route path="/Products" element={<ProductssPage />} />
          <Route path="/productlist" element={<ProductPage />} />
          <Route path="/product/:slug" element={<ProductDetails />} />
          <Route path="/categories" element={<Categories />} />
          <Route path="/cart" element={<CartPage />} />
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="/category/:slug" element={<CategoryProduct />} />

          {/* <Route path="/user/profilee" element={<Profile />} /> */}
          {/* <Route path="user/wishlist" element={<Wishlist />} />
          <Route path="user/orderhistory" element={<Orders />} />
          <Route path="user/address" element={<Address/>} /> */}

          <Route
            path="/dashboard"
            // element={<PrivateRoute />}
          >
            <Route path="user/profile" element={<Dashboard />} />
            <Route path="user/orderhistory" element={<Orders />} />
            <Route path="user/wishlist" element={<Wishlist />} />
            <Route path="user/changepassword" element={<ChangePswd />} />
            <Route path="user/address" element={<Address/>} />
          </Route>

          <Route path="/dashboard" element={<AdminRoute />}>
            <Route path="admin" element={<AdminDashboard />} />
            <Route path="admin/create-category" element={<CreateCategory />} />
            <Route path="admin/create-product" element={<CreateProduct />} />
            <Route path="admin/product/:slug" element={<UpdateProduct />} />
            <Route path="admin/products" element={<Products />} />
            <Route path="admin/users" element={<Users />} />
            <Route path="admin/orders" element={<AdminOrders />} />
          </Route>

          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/login/forgot-password" element={<ForgotPasssword />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/Services" element={<ServicePage />} />
          <Route path="/services" element={<Categories />} />
          <Route path="/handcraft" element={<HandcraftsPage />} />
          <Route path="/gifting" element={<Gifting />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
          <Route path="/Return-Refund-policy" element={<ReturnRefundPolicy/>} />
          <Route path="/Terms-Conditions-policy" element={<TermsAndConditions/>} />
        </Routes>
      <Toaster />
    </>
  );
}

export default App;
