import React from "react";
// import "./Support.css";
import "./BookFlip.css";
import Support_1 from "../../Assets/support_1.jpg";
import Support_2 from "../../Assets/support_2.jpg";
import Support_3 from "../../Assets/support_3.jpg";
import Support_4 from "../../Assets/support_4.jpg";
import logo from "../../Assets/logo.png";

export const Support = () => {
  return (
    <div className="book">
      <div className="left-supp-imges">
        <div className="row">
          <div className="column">
            <img
              src={Support_1}
              alt="people-meeting"
              style={{ width: "100%", height: "248px" }}
            />
          </div>
          <div className="column">
            <img
              src={Support_2}
              alt="at-farm-discussion"
              style={{ width: "100%", height: "248px" }}
            />
          </div>
        </div>
        <div className="row">
          <div className="column">
            <img
              src={Support_3}
              alt="plant-doctor-magnifying-glass"
              style={{ width: "100%", height: "248px" }}
            />
          </div>
          <div className="column">
            <img
              src={Support_4}
              alt="laptop-meeting"
              style={{ width: "100%", height: "248px" }}
            />
          </div>
        </div>
      </div>
      <div className="right-supp-info">
        <div>
          <h6
            style={{
              fontSize: "80px",
              fontWeight: "100",
            }}
          >
            CUSTOMER SUPPORT
          </h6>
        </div>
        <div>
          <p>
            Our company has a dedicated customer support team to provide
            immediate response to the farmers through online or offline mode.
            The support system ensures that the expert addresses the customer's
            problems pertaining to the sandalwood tree. The company also has an
            option of onsite customer support visit by an expert to examine the
            problem and provide solution immediately.
          </p>
        </div>
        {/* <div className="info-supp-logo">
          <img src={logo} alt="logo" />
        </div> */}
      </div>
    </div>
  );
};
export default Support;
