import React from "react";
import "./Hero.css";
import { useNavigate } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import hero1 from "../../Assets/hero-img1c.png";
import hero2 from "../../Assets/hero-img2.png";
import hero3 from "../../Assets/hero-img3.png";
import hero4 from "../../Assets/hero-img4.png";
import bubble_1 from "../../Assets/bubble1.png";
import bubble_3 from "../../Assets/bubble3.png";
// import mobimg from "../../Assets/heri-mob-img.png";
import perlimg from "../../Assets/pearl.png";
import d_leaf from "../../Assets/d_leaf.png";
import r_leaf from "../../Assets/r_leaf.png";

const Hero = () => {
  const navigate = useNavigate();

  const handleButtonClick1 = () => {
    window.scrollTo(0, 0);
    navigate("/products");
  };

  const handleButtonClick2 = () => {
    window.scrollTo(0, 0);
    navigate("/gifting");
  };

  return (
    <div className="hero">
      <Carousel
        showArrows={true}
        showStatus={false}
        autoPlay={true}
        infiniteLoop={true}
        interval={3000}
      >
        {/* Slide1 */}
        <div className=" heroslideone row position-relative">
          <div className="col-md-6 hero1-left text-center order-lg-1 order-md-2">
            <div className="d-flex flex-column gap-3 text-center flex-wrap hero1-inner-left">
              <span>
                <img className="perlimg d-md" src={perlimg} alt="" />
              </span>
              <span>
                <img className="d_leaf d-md" src={d_leaf} alt="" />
              </span>
              <span>
                <img className="r_leaf d-md" src={r_leaf} alt="" />
              </span>
              <h1 className="h1-typo headerr">
                Unearth the essence of Sandalwood elegance
              </h1>
              <p className="p-typo sbhead">
                Indulge in the alluring world of sandalwood with SandAura and
                experience the epitome of natural luxury that only sandalwood
                can offer.
              </p>
              <div className="text-lg-start text-md-center">
                <a onClick={handleButtonClick1} className="hero-btn">
                  EXPLORE MORE
                </a>
              </div>
            </div>
          </div>

          <div className="col-md-6 hero1-right order-lg-2 order-md-1" >
            <img className="des-img sgg" src={hero1} alt="" />
            {/* <img className="d-lg-none mob-img" src={mobimg} alt="" /> */}
          </div>
          <img className="bubbles" id="bubble1" src={bubble_3} alt="" />
          <img className="bubbles" id="bubble2" src={bubble_3} alt="" />
          <img className="bubbles" id="bubble3" src={bubble_3} alt="" />
          <img className="bubbles" id="bubble4" src={bubble_1} alt="" />
        </div>

        {/* slide2 */}
        <div className="heroslidetwo hero2 position-relative">
          <div className="hero2-left d-flex flex-column text-start order-md-2">
            <h1 className="h1-typo" id="headhero2">
              Your Journey to Sandalwood Excellence Begins Here
            </h1>
            <p className="p-typo sbhead" id="phero2">
              Your Path to Profit: Plant, Protect, and Prosper with the our Best
              Sandalwood Specialists
            </p>
            <div className="text-lg-start text-md-center">
              <a href="" className="hero-btn">
                EXPLORE MORE
              </a>
            </div>
          </div>

          <div className="hero2-right order-md-1">
            <img src={hero2} alt="" />
          </div>
        </div>

        {/* slide3 */}
        <div className="heroslidethree row hero3">
          <div className="col-md-6">
            <div className="d-flex hero3-left flex-column gap-3 text-start flex-wrap orderr-md-2 order-lg-1">
              <h1 className="h1-typo">Sandalwood Pioneers</h1>
              <p className="p-typo sbhead">
                Reviving Indian Legacy: Pioneering Sandalwood Excellence,
                Cultivating <br /> India's Pride, and Leading the Way to a
                Fragrant Future
              </p>
              <div className="text-lg-start text-md-center">
                <a href="" className="hero-btn">
                  EXPLORE MORE
                </a>
              </div>
            </div>
          </div>

          <div className="col-md-6 hero3-right order-md-1 order-lg-2">
            <img src={hero3} alt="" />
          </div>
        </div>

        {/* slide4 */}
        <div className="heroslidefour row hero4">
          <div className="col-md-6 order-md-2 order-lg-1">
            <div className="d-flex hero4-left flex-column gap-lg-3 gap-md-1 text-start flex-wrap">
              <h1 className="h1-typo">Har Ghar Chandan</h1>
              <p className="p-typo sbhead">
                Bringing Chandan Home: Reviving India's Rich Legacy, One <br />
                Household at a Time.
              </p>
              <div className="text-lg-start text-md-center">
                <a onClick={handleButtonClick2} className="hero-btn">
                  EXPLORE MORE
                </a>
              </div>
            </div>
          </div>

          <div className="col-md-6 hero4-right flex-column order-md-1 order-lg-2">
            <img src={hero4} alt="" />
          </div>
        </div>
      </Carousel>
    </div>
  );
};

export default Hero;
