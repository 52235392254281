import React from 'react'
import { useNavigate } from 'react-router-dom';
import './FreeBeauty.css'
// import tea_image from '../../Assets/tea_image.jpg'
// import tea_mobileimg from '../../Assets/beauty_mobile.png'
import Free_Beauty from '../../Assets/Free_Beauty.jpg'



export const Freebeauty = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    window.scrollTo(0, 0);
    navigate('/products');
  };

  return (
    <div className='Freebeauty-main'>
    <div className='Freebeauty-first'>
      {/* <img className='btlg' src={Free_Beauty} alt="img" /> */}
      {/* <img className='btsm' src={Free_Beauty} alt="img" /> */}
      <div className="Freebeauty-content">
        <h1>Clean, Cruelty Free Beauty</h1>
        <p>The Ayurvedic standard for beauty was always pure, fresh, seasonal and natural.</p>
        <button className='Freebeauty-btn' onClick={handleButtonClick}>EXPLORE PRODUCTS</button>
      </div>
    </div>
    </div>
  )
}

export default Freebeauty;