import React, { useEffect, useState, useRef } from "react";
import "../Styles/ProductItem.css";
import Layout from "../Components/Layout/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as farHeart } from "@fortawesome/free-regular-svg-icons";
import { faHeart as fasHeart } from "@fortawesome/free-solid-svg-icons";
import { faShareAlt } from "@fortawesome/free-solid-svg-icons";
import toast from "react-hot-toast";
import axios from "axios";
import Footer from "../Components/Layout/Footer";
import { useParams } from "react-router-dom";
import SimilarProduct from "./SimilarProduct";

export const ProductItem1 = () => {
  const { slug: id } = useParams();
  const [cartItems, setCartItems] = useState([]);
  const [productData, setProductData] = useState(null);
  const [wishlistClicked, setWishlistClicked] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [selectedSize, setSelectedSize] = useState(null);
  const [pincode, setPincode] = useState("");
  const [message, setMessage] = useState("");
  const [apiFailed, setApiFailed] = useState(false);

  const defaultImage =
    "https://s3-us-west-2.amazonaws.com/s.cdpn.io/1255/image-not-found.svg";

  const toggleWishlist = () => {
    setWishlistClicked(!wishlistClicked);
  };

  const accessToken = "1";
  const fetchCartItems = async () => {
    try {
      const response = await axios.get(
        "https://rispl.backendapihub.com/cart/show",
        {
          headers: {
            "access-token": accessToken,
          },
        }
      );
      if (response.status === 200) {
        if (Array.isArray(response.data.data)) {
          setCartItems && setCartItems(response.data.data);
        } else {
          console.error("Cart items data is not an array");
          setCartItems && setCartItems([]);
        }
      } else {
        console.error("Failed to fetch cart items");
      }
    } catch (error) {
      console.error("Error fetching cart items:", error);
      setCartItems && setCartItems([]);
    }
  };

  useEffect(() => {
    fetchCartItems();
  }, []);

  useEffect(() => {
    if (!id || isNaN(id)) {
      console.error("Invalid l1_id:", id);
      return;
    }
    axios
      .get(`https://rispl.backendapihub.com/product/detailview/${id}`)
      .then((response) => {
        setProductData(response.data);
        if (
          response.data &&
          response.data.data &&
          response.data.data.length > 0 &&
          response.data.data[0].media &&
          response.data.data[0].media.length > 0
        ) {
          setSelectedImage(response.data.data[0].media[0]);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setApiFailed(true);
      });
  }, [id]);

  useEffect(() => {
    console.log("Selected image:", selectedImage);
  }, [selectedImage]);

  const handleCheckPincode = async () => {
    console.log("Button clicked"); // Debugging log

    if (pincode.length !== 6 || isNaN(pincode)) {
      setMessage("Please enter a valid 6-digit pincode.");
      return;
    }

    try {
      const response = await fetch(
        `https://rispl.backendapihub.com/address/getPincode?pincode=${pincode}`,
        {
          method: "GET",
          headers: {
            accept: "application/json",
          },
        }
      );

      console.log("Response received");

      if (response.ok) {
        const data = await response.json();
        console.log("Data:", data);
        if (
          data.status.toLowerCase() === "success" &&
          data.message === "Pincode found"
        ) {
          setMessage("Delivery available for this pincode");
        } else {
          setMessage("Unfortunately we do not ship to your pincode");
        }
      } else {
        setMessage("Unfortunately we do not ship to your pincode");
      }
    } catch (error) {
      console.error("Error fetching pincode:", error);
      setMessage("Error: Unable to fetch data.");
    }
  };

  useEffect(() => {
    const stars = document.querySelectorAll(".star");

    stars.forEach((star, index) => {
      star.addEventListener("click", function () {
        resetStars();
        for (let i = 0; i <= index; i++) {
          stars[i].classList.add("active");
        }
      });
    });

    function resetStars() {
      stars.forEach((star) => {
        star.classList.remove("active");
      });
    }

    return () => {
      stars.forEach((star) => {
        star.removeEventListener("click", resetStars);
      });
    };
  }, []);

  function hideAllExcept(idToKeepVisible) {
    let buttons = document.querySelectorAll(".opendes, .openpro, .openkey");

    buttons.forEach((button) => {
      let ids = button.id;
      console.log(ids);
      if (ids === idToKeepVisible) {
        button.classList.add("active");
      } else {
        button.classList.remove("active");
      }
    });

    var divs = document.querySelectorAll(".desc, .prof, .key");

    divs.forEach(function (div) {
      if (div.id === idToKeepVisible) {
        div.classList.remove("d-none");
      } else {
        div.classList.add("d-none");
      }
    });
  }

  function openDes() {
    hideAllExcept("des");
  }

  function openPro() {
    hideAllExcept("pro");
  }

  function openKey() {
    hideAllExcept("key");
  }

  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlay = () => {
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setIsPlaying(false);
      }
    };

    const handleClickOutside = (event) => {
      if (!event.target.closest(".tes-triangle")) {
        setIsPlaying(false);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const addToCart = () => {
    if (!selectedSize) {
      toast.error("Please select a size.");
      return;
    }

    const data = {
      l1_id: id,
      quantity: 1,
      size_id: selectedSize.size_id,
    };
    const accessToken = "1";
    axios
      .post("https://rispl.backendapihub.com/cart/add", data, {
        headers: {
          "access-token": accessToken,
        },
      })
      .then((response) => {
        if (response.status == 200) {
          toast.success(response.data.message);
          fetchCartItems();
        } else {
          toast.error("Failed to add item to cart.");
        }
      })
      .catch((error) => {
        toast.error("Error adding item to cart.");
      });
  };

  if (!productData) {
    if (apiFailed) {
      return (
        <Layout>
          <div className="ProductItem p-5">
            <div className="row m-0">
              <div className="col-lg-4 mb-3 mb-lg-0">
                <img src={defaultImage} alt="No images available" />
              </div>
              <div className="col-lg-8 mb-3 mb-lg-0">
                <p>
                  Product details are currently unavailable. please try again
                  later.{" "}
                </p>
              </div>
            </div>
          </div>
        </Layout>
      );
    } else {
      return (
        <Layout>
          <div className="ProductItem p-5">
            <div className="row m-0">
              <div className="col-lg-4 mb-3 mb-lg-0">
                <img src={defaultImage} alt="Loading image" />
              </div>
              <div className="col-lg-8 mb-3 mb-lg-0">
                <p>Loading image...</p>
              </div>
            </div>
          </div>
        </Layout>
      );
    }
  }

  const {
    category_name,
    product_name,
    sku,
    media,
    size,
    colour,
    description,
    similar_products,
  } = productData.data[0];

  return (
    <Layout setCartItems={setCartItems} cartItems={cartItems} isNavbar={true}>
      <div className="item-top-section"></div>
      <div className="deatils-layer"></div>
      <div className="ProductItem p-5">
        <div className="row m-0">
          <div className="col-lg-4 mb-3 mb-lg-0">
            <div className="product-img">
              {media && media.length > 0 ? (
                media.map((imgSrc, index) => (
                  <img
                    key={index}
                    src={`https://rispl.backendapihub.com/images/${imgSrc}`}
                    alt={`product-img-${index}`}
                    onClick={() => setSelectedImage(imgSrc)}
                    onMouseEnter={() => setSelectedImage(imgSrc)}
                  />
                ))
              ) : (
                <img src={defaultImage} alt="No images available" />
              )}
            </div>
          </div>

          <div className="col-lg-4 mb-3 mb-lg-0">
            <div className="small-one rgtprd d-flex flex-column align-items-center p-2">
              {selectedImage ? (
                <img
                  className="test img-fluid w-100"
                  src={`https://rispl.backendapihub.com/images/${selectedImage}`}
                  alt=""
                />
              ) : (
                <img src={defaultImage} alt="Loading image" />
              )}

              <div className="icons-container">
                <FontAwesomeIcon
                  icon={wishlistClicked ? fasHeart : farHeart}
                  className={`wishlist-icon ${
                    wishlistClicked ? "clicked" : ""
                  }`}
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleWishlist();
                  }}
                />
                <FontAwesomeIcon
                  icon={faShareAlt}
                  className="share-alt-icon"
                  onClick={(e) => e.stopPropagation()}
                />
              </div>
              <div className="new-layer"></div>
            </div>
          </div>
          <div className="col-lg-4 mb-3 mb-lg-0">
            <div className="small-none py-4" id="jkjk">
              <div className="content-one">
                <h1>{product_name}</h1>
                <h6>
                  SKU : <span>{sku}</span>
                </h6>
                <p>{description}</p>
              </div>
              <div className="content-two d-flex flex-wrap gap-3 align-items-center my-4">
                <h6 className="m-0">Size</h6>
                <div className="size-options">
                  {size &&
                    size.map((variation, index) => (
                      <a
                        key={index}
                        className={`item-btn ${
                          selectedSize === variation ? "selected" : ""
                        }`}
                        onClick={() => {
                          console.log("Selected size:", variation);
                          setSelectedSize(variation);
                        }}
                      >
                        {variation.size_name}
                      </a>
                    ))}
                </div>
              </div>
              <div className="content-three d-flex flex-column my-4" id="sdcp">
                <span>₹ {size && size.length > 0 && size[0].price}</span>
                <p className="m-0">(MRP INCLUSIVE OF ALL TAXES)</p>
              </div>

              <div className="pincode-input mb-4">
                <label htmlFor="pincode">Delivery Options</label>
                <div className="input-container">
                  <input
                    type="text"
                    placeholder="Enter Pincode"
                    id="pincode"
                    value={pincode}
                    onChange={(e) => setPincode(e.target.value)}
                    className="form-control"
                    maxLength="6"
                  />
                  <button
                    type="button"
                    className="check"
                    onClick={handleCheckPincode}
                  >
                    Check
                  </button>
                </div>
                {message && (
                  <p
                    style={{
                      color:
                        message === "Delivery available for this pincode"
                          ? "#00FF00"
                          : "#FF0000",
                    }}
                  >
                    {message}
                  </p>
                )}
              </div>

              <div className="d-flex flex-column gap-2 my-5">
                <a className="purchase-btn cart-btn" onClick={addToCart}>
                  ADD TO CART
                </a>
                <a className="purchase-btn buy-btn">BUY NOW</a>
              </div>
              <div>
                <div className="content-four d-flex justify-content-start gap-4 align-items-center">
                  <h6 id="des" className="opendes m-0" onClick={openDes}>
                    Description
                  </h6>
                  <h6 id="pro" className="openpro m-0" onClick={openPro}>
                    Benefits
                  </h6>
                  <h6 id="key" className="openkey m-0" onClick={openKey}>
                    Key Ingredients
                  </h6>
                </div>
                <hr />
                <div id="des" className="desc">
                  <p className="para">
                    A fresh flower infused body mist with aromatic Nargis to
                    soften, hydrate and scent the skin. This Forest Essentials
                    Body Mist Nargis has an uplifting and sensuous aroma with
                    floral notes.
                  </p>
                  <p className="para">
                    Our Body Mists feature a unique blend of scented essential
                    oils and fresh Aloe Vera juice to create fragrances that not
                    only delight your senses but also leave your skin hydrated
                    for long hours.
                  </p>
                </div>
                <div id="pro" className="prof d-none">
                  <p className="para">
                    A fresh flower infused body mist with aromatic Nargis to
                    soften, hydrate and scent the skin. This Forest Essentials
                    Body Mist Nargis has an uplifting and sensuous aroma with
                    floral notes
                  </p>
                </div>
                <div id="key" className="key d-none">
                  <p className="para">
                    Our Body Mists feature a unique blend of scented essential
                    oils and fresh Aloe Vera juice to create fragrances that not
                    only delight your senses but also leave your skin hydrated
                    for long hours.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="Best-sellings " id="pdsm">
        {similar_products && similar_products.length > 0 && (
          <SimilarProduct products={similar_products} />
        )}
      </div>
      <Footer showBothDivs={false} />
    </Layout>
  );
};
export default ProductItem1;
