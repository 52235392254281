import p1_img from './pd1.png'
// import p2_img from './pd2.png'
import p3_img from './pd3.png'
import p4_img from './pd4.png'
import p5_img from './pd5.png'
// import p6_img from './pd2.png'

let all_product = [
    {
        id:1,
        name:"Organic Cold Pressed Virgin Oil Coconut",
        desc:"Hydrates & Scents the Skin",
        image:p1_img,
        price: `1,295.00`,
    },
    {
        id:2,
        name:"Organic Cold Pressed Virgin Oil Coconut",
        desc:"Hydrates & Scents the Skin",
        // image:p2_img,
        price: `1,295.00`,
    },
    {
        id:3,
        name:"Organic Cold Pressed Virgin Oil Coconut",
        desc:"Hydrates & Scents the Skin",
        image:p3_img,
        price: `1,295.00`,
    },
    {
        id:4,
        name:"Organic Cold Pressed Virgin Oil Coconut",
        desc:"Hydrates & Scents the Skin",
        image:p4_img,
        price: `1,295.00`,
    },
    {
        id:5,
        name:"Organic Cold Pressed Virgin Oil Coconut",
        desc:"Hydrates & Scents the Skin",
        image:p5_img,
        price: `1,295.00`,
    },
    {
        id:6,
        name:"Organic Cold Pressed Virgin Oil Coconut",
        desc:"Hydrates & Scents the Skin",
        // image:p6_img,
        price: `1,295.00`,
    },
];

export default all_product;

