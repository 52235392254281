import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

i18n.use(LanguageDetector).use(initReactI18next).init({
    debug: true,
    lng:"",
    fallbackLng: "en",
    detection:{
        order:['cookie','localStorage','htmlTag','querystring',],
        caches: ['cookie'],
    },
    resources:{
        en:{
            translation:{
                HOME: 'HOME',
                ABOUT: 'ABOUT US',
                PRODUCTS: 'PRODUCTS',
                SERVICES: 'SERVICES',
                HANDICRAFTS: 'HANDICRAFTS',
                GIFTING: 'HAR GHAR CHANDAN (GIFTING)',
            }
        },
        hi:{
            translation:{
                HOME: 'होम',
                ABOUT: 'एबाउट अस',
                PRODUCTS: 'प्रोडक्ट्स',
                SERVICES: 'सेवाएँ',
                HANDICRAFTS: 'हस्तशिल्प',
                GIFTING: 'हर घर चंदन (गिफ्टिंग)',
            }
        },
        // mr:{
        //     translation:{
        //         HOME: 'HOME'
        //     }
        // },
        // ta:{
        //     translation:{
        //         HOME: 'HOME'
        //     }
        // }
    }
})