import HTMLFlipBook from "react-pageflip";
import { PageFlip } from "page-flip";
import BookCover from "./BookCover";
import Information from "./Information";
import AboutUs from "./AboutUs";
import CoreTeam from "./CoreTeam";
import WhyChooseUs from "./WhyChooseUs";
import WeHelpU from "./WeHelpU";
import Plantation from "./Plantation";
import PlantSelection from "./PlantSelection";
import Maintenance from "./Maintenance";
import Assessment from "./Assessment";
import Development from "./Development";
import Support from "./Support";
import Responsibility from "./Responsibility";
import Consultancy from "./Consultancy";
import OurProduct from "./OurProduct";
import Farm from "./Farm";

function BookFlip() {
  return (
    <HTMLFlipBook
      renderOnlyPageLengthChange={true}
      autoSize={false}
      drawShadow={true}
      width={1200}
      height={500}
    >
      <div>
        <BookCover />
      </div>
      {/* <div>
        <Farm/>
      </div> */}
      <div>
        <Information />
      </div>
      <div>
        <AboutUs />
      </div>
      <div>
        <CoreTeam />
      </div>
      <div>
        <WhyChooseUs />
      </div>
      <div>
        <WeHelpU />
      </div>
      <div>
        <Plantation />
      </div>
      <div>
        <PlantSelection />
      </div>
      <div>
        <Maintenance />
      </div>
      <div>
        <Assessment />
      </div>
      <div>
        <Development />
      </div>
      <div>
        <Support />
      </div>
      <div>
        <Responsibility />
      </div>
      <div>
        <Consultancy />
      </div>
      <div>
        <OurProduct />
      </div>
    </HTMLFlipBook>
  );
}
export default BookFlip;
