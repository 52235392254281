import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import Modal from "react-modal";
import "./Testimonial.css";
import left_button from "../../Assets/left_arrow.png";
import right_button from "../../Assets/right_arrow.png";
import tes_img from "../../Assets/tes-img.png";
import tes_video1 from "../../Assets/review_video1.mp4";
import tes_video2 from "../../Assets/review_video2.mp4";
import video1_thumbnail from "../../Assets/video1_thumbnail.png";
import video2_thumbnail from "../../Assets/video2_thumbnail.png";
import video1_img from "../../Assets/video1_img.png";
import video2_img from "../../Assets/video2_img.png";
import tes_header from "../../Assets/tes-card-header.png";
import tes_footer from "../../Assets/tes-footer-img.png";
import tes_flower from "../../Assets/tes-flower.png";
import semicolon from "../../Assets/cln.png";
import plybtn from "../../Assets/playbtnvt.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWindowClose } from '@fortawesome/free-solid-svg-icons';

const Testimonial = () => {
  let sliderRef = useRef(null);
  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };

  const settings = {
    autoplay: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentVideo, setCurrentVideo] = useState(null);

  const openModal = (video) => {
    setCurrentVideo(video);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setCurrentVideo(null);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        closeModal();
      }
    };

    const handleClickOutside = (event) => {
      if (!event.target.closest(".tes-triangle") && !event.target.closest(".video-element")) {
        closeModal();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className="testimonial">
      <div className="container d-flex justify-content-between px-5">
        <h1 className="tes-titlee">What our clients say about us</h1>
      </div>

      <div className="tes-main-container position-relative">
        <img className="tes-flower" src={tes_flower} alt="" />
        <div className="container">
          <Slider
            ref={(slider) => {
              sliderRef = slider;
            }}
            {...settings}
          >
            {/* third card */}
            <div className="item-box" key={3}>
              <div className="tes-card">
                <div className="card-header mb-3">
                  <img className="semu" src={semicolon} alt="" />
                  <br />
                  <img src={video2_thumbnail} className="lastimg" alt="..." />
                </div>
                <div className="tes-card-body position-relative">
                  <div className="tes-playbutton d-flex justify-content-center align-items-center">
                    <div className="tes-inner-play-btn d-flex justify-content-center align-items-center">
                      <div className="tes-triangle">
                        <img
                          src={plybtn}
                          alt="playbtn"
                          onClick={() => openModal(tes_video1)}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="card-footer d-flex gap-3">
                  <div className="tes-footer-img">
                    <img src={video1_img} alt="" />
                  </div>
                  <div className="tes-footer-contentt">
                  <h1 className="m-0">Siddalingaiah</h1>
                    <p className="m-0">Farmer</p>
                  </div>
                </div>
              </div>
            </div>

            {/* fourth card */}
            <div className="item-box box-left" key={4}>
              <div className="tes-card">
                <div className="card-header mb-3">
                  <img className="semu" src={semicolon} alt="" />
                  <br />
                  <img className="lastimg" src={video1_thumbnail} alt="" />
                </div>
                <div className="tes-card-body position-relative">
                  <div className="tes-playbutton d-flex justify-content-center align-items-center">
                    <div className="tes-inner-play-btn d-flex justify-content-center align-items-center">
                      <div className="tes-triangle">
                        <img
                          src={plybtn}
                          alt="playbtn"
                          onClick={() => openModal(tes_video2)}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="card-footer d-flex gap-3">
                  <div className="tes-footer-img">
                    <img src={video2_img} alt="" />
                  </div>
                  <div className="tes-footer-contentt">
                    <h1 className="m-0">Rajanna</h1>
                    <p className="m-0">Farmer</p>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className="video-modal"
        overlayClassName="video-overlay"
      >
        <FontAwesomeIcon
          icon={faWindowClose}
          className="close-button"
          onClick={closeModal}
        />
        {currentVideo && (
          <video autoPlay controls className="video-element">
            <source src={currentVideo} type="video/mp4" />
          </video>
        )}
      </Modal>
    </div>
  );
};

export default Testimonial;
