import React,{useEffect, useState} from "react";
import Layout from "../Components/Layout/Layout";
import "../Styles/Testvideo.css";
import Navbar from "../Components/Layout/Navbar";



const HandcraftsPage = () => {
  
  return (
    <Layout>
      <br/><br/><br/><br/><br/><br/><br/><br/>
  <h1>Handicraft Page</h1>
  </Layout>
  );
  }

export default HandcraftsPage;