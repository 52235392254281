import React, { useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Autocomplete, Typography } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const validationSchema = Yup.object().shape({
  address1: Yup.string().trim().required('Required'),
  address2: Yup.string().trim().required('Required'),
  city: Yup.string().trim().required('Required'),
  stateId: Yup.number().required('Required'),
  pin: Yup.number().nullable().required('Required').min(100000, 'Invalid Pin').max(999999, 'Invalid Pin'),
});

const defaultValues = {
  address1: '',
  address2: '',
  city: '',
  stateId: null,
  pin: '',
};

const states = [
  { id: 1, name: 'Maharashtra' },
  { id: 2, name: 'Gujarat' },
];

export default function AddAddressPopup({ open, onClose, onAddAddress }) {
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const { handleSubmit, reset, control, setValue, watch } = methods;

  const onSubmit = async (formData) => {
    const payload = {
      address1: formData.address1,
      address2: formData.address2,
      city: formData.city,
      stateId: formData.stateId, 
      pincode: formData.pin,
      is_default: 0,
    };

    try {
      const response = await fetch('https://rispl.backendapihub.com/address/add', {
        method: 'POST',
        headers: {
          'accept': 'application/json',
          'access-token': '1', 
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const result = await response.json();
        onAddAddress(result); 
        onClose();
      } else {
        console.error('Failed to add address:', response.statusText);
      }
    } catch (error) {
      console.error('Error adding address:', error);
    }
  };

  useEffect(() => {
    if (!open) {
      reset(defaultValues);
    }
  }, [open, reset]);

  // Watch stateId field to update the Autocomplete value
  const watchedStateId = watch('stateId');

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
          Add New Address
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                name="address1"
                label="Address Line 1"
                multiline
                maxRows={2}
                fullWidth
                {...methods.register('address1')}
                error={Boolean(methods.formState.errors.address1)}
                helperText={methods.formState.errors.address1?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="address2"
                label="Address Line 2"
                multiline
                maxRows={2}
                fullWidth
                {...methods.register('address2')}
                error={Boolean(methods.formState.errors.address2)}
                helperText={methods.formState.errors.address2?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="city"
                label="City"
                fullWidth
                {...methods.register('city')}
                error={Boolean(methods.formState.errors.city)}
                helperText={methods.formState.errors.city?.message}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name="stateId"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    options={states}
                    getOptionLabel={(option) => option.name}
                    value={states.find((state) => state.id === field.value) || null}
                    onChange={(event, value) => {
                      setValue('stateId', value ? value.id : null);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="State"
                        error={Boolean(methods.formState.errors.stateId)}
                        helperText={methods.formState.errors.stateId?.message}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="pin"
                label="Pincode"
                fullWidth
                inputProps={{ maxLength: 6 }}
                {...methods.register('pin')}
                error={Boolean(methods.formState.errors.pin)}
                helperText={methods.formState.errors.pin?.message}
              />
            </Grid>
          </Grid>
          <DialogActions sx={{ mt: 5 }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{ backgroundColor: 'rgb(84, 214, 44)', '&:hover': { backgroundColor: 'rgb(84, 214, 44)' } }}
            >
              Submit
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={onClose}
              sx={{ backgroundColor: 'rgb(255, 72, 66)', '&:hover': { backgroundColor: 'rgb(255, 72, 66)' } }}
            >
              Cancel
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}
