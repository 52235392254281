import React from "react";
import { useNavigate } from "react-router-dom";
import "./TissueBanner.css";
import bannerimg from "../../Assets/Tissue-img.jpg";
import tissue_mob from "../../Assets/tissue_mob.png";
import cruelimg_mob from "../../Assets/cruelimg_mob.png";
import art1 from "../../Assets/banner-art1.png";
import art2 from "../../Assets/banner-art2.png";
// import bannericon from '../../Assets/bannericon.jpg'
// import gmp from '../../Assets/gmp.png'
import img1 from "../../Assets/cruelty.png";
import img2 from "../../Assets/organic.png";
import img3 from "../../Assets/MI.png";
import img4 from "../../Assets/quality.jpg";
// import img4 from "../../Assets/silvery-badge-best-quality.png";
import img5 from "../../Assets/np.png";

const TissueBanner = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    window.scrollTo(0, 0);
    navigate("/products");
  };
  return (
    <div className="banner-main">
      <div className="banner-first">
        <img className="banlg" src={bannerimg} alt="img" />
        <img className="bansm" src={tissue_mob} alt="img" />
        <div className="banner-content">
          <h1>Tissue Cultured Plant</h1>
          <p>
            The Ayurvedic standard for beauty was always pure, fresh, seasonal
            and natural.
          </p>
          <button className="banner-btn" onClick={handleButtonClick}>
            EXPLORE PRODUCTS
          </button>
        </div>
      </div>

      <div className="banner-second">
        <h1>Clean, Cruelty Free Beauty</h1>
        <p>
          The Ayurvedic standard for beauty was always pure, fresh, seasonal and
          natural. Across all our products, we control the conception,
          formulation, manufacturing and sale of our Ayurvedic products, thereby
          ensuring quality & purity across all pillars. We use this philosophy
          to produce our line of treatments and our certifications attest to
          this.
        </p>
        <img className="absulate-art" id="art1" src={art1} alt="art" />
        <img className="absulate-art" id="art2" src={art2} alt="art" />
      </div>

      <div className="banner-third">
        <img className="bansticklg" src={img1} alt="cruelty" />
        <img className="bansticklg" src={img2} alt="organic" style={{width: "8%"}}/>
        <img className="bansticklg" src={img3} alt="MI" />
        <img className="bansticklg" src={img4} alt="quality" style={{width: "8%"}}/>
        <img className="bansticklg" src={img5} alt="np" />
        <img className="bansticksm" src={cruelimg_mob} alt="mobile" />
      </div>
    </div>
  );
};

export default TissueBanner;
